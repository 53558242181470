import React, { Component } from 'react';
import styled from "styled-components";
// Images
import ic_close from '../../../images/ic_close_white.svg';
import ic_notification_success from '../../../images/ic_notification_success.svg';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';

const Bound = styled.div`
    padding: 14.1px 14.2px 16px 12px;
    background-color: #fafafa;
    width: 300px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .popupHeader{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
        img{
            margin: 5.9px 90px 0 0;
        }
    }
    button{
        margin: 0 auto;
        margin-top: 38px;
    }
`

class PopupSuccess extends Component {
    render() {
        return (
            <Bound>
                 <div className="popupHeader">
                    <img src = {ic_notification_success} alt = 'ic_success'/>
                    <span onClick={() => this.props.outPopup()} className='ic_close'></span>
                </div>
                <h4 className='title'>{this.props.titleHeader}</h4>
                <ButtonConfirmPopup titleConfirm = 'xác nhận' />
            </Bound>
        );
    }
}

export default PopupSuccess;