import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
// import { getInfoImageOrVideo } from '../tools';

// api
const apiCreateCounter = host + "admin/counter/create";
const apiUpdateCounter = host + "admin/counter/update";
const apiGetCounterByOffice = host + "admin/counter/get-counter-by-office";
const apiDeleteCounter = host + "admin/counter/delete";
const apiGetListTypeCounterServices = host + "admin/counter-services/getAll"
Axios.defaults.withCredentials = true;

/**
 * create counter
 * @param {{
 * OfficeID*: string,
 * CounterNum*: string,
 * ServAllService*: boolean, - check serve all service, if this field is false, it's must be removed in object
 * IsVIP*: boolean, - set priority counters
 * ServiceList*: array[string], - list id of services, if ServAllService === true, this field was remove in object
 * CounterName*: string
 * }} dataCreate data used to create counter
 */
export const createCounter = (dataCreate) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ...dataCreate
  })

  try {
    const res = await Axios.post(apiCreateCounter, queryDataFormat);

    console.log(res);

    // const {data} = res;
    dispatch({
      type: types.CREATE_COUNTER      
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.CREATE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {{
 * CounterID*: string,
 * OfficeID: string,
 * CounterNum: string,
 * ServAllService: boolean,
 * ServiceList: array[string],
 * CounterName: string
 * }} dataUpdate 
 */
export const updateCounter = (dataUpdate) => async dispatch => {
  const queryFormatData = JSON.stringify({
    ...dataUpdate
  })

  try {
    const res = await Axios.post(apiUpdateCounter, queryFormatData);

    console.log(res);
    dispatch({
      type: types.UPDATE_COUNTER
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.UPDATE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} officeId id of office
 */
export const getCounterByOffice = (officeId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    OfficeID: officeId
  })

  try {
    const res = await Axios.post(apiGetCounterByOffice, queryDataFormat);

    console.log(res);
    const {data} = res;
    dispatch({
      type: types.GET_COUNTER_BY_OFFICE,
      listCounter: data.ListCounter
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.GET_COUNTER_BY_OFFICE_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} counterId id of counter
 */
export const deleteCounter = (counterId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId
  })

  try {
    const res = await Axios.post(apiDeleteCounter, queryDataFormat);
    console.log(res);

    dispatch({
      type: types.DELETE_COUNTER
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.DELETE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} userId id of user was loged in to web
 */
export const getListTypeCounterServices = (userId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    UID: userId
  })

  try {
    const res = await Axios.post(apiGetListTypeCounterServices, queryDataFormat);

    console.log(res);
    const {data} = res;

    dispatch({
      type: types.GET_LIST_TYPE_COUNTER_SERVICES,
      listService: data.ListService
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.GET_LIST_TYPE_COUNTER_SERVICES_ERR,
      err
    })
  }
}