import React, { Component } from 'react'
import styled from 'styled-components'

//Img
import ic_more_gray from '../../../../images/ic_more_gray.svg'
import SortControl from '../Control/PagningControl';
import { formatNumber } from '../../../../tools';
import { setPermissionSingleChoise } from '../../../../data/singleChoiseData';

const Bound = styled.div`
    display:flex;
    flex:1;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    overflow:hidden;
    table{
        display:flex;
        flex:1;
        flex-direction:column;
        border-collapse: collapse; 
        width:100%;
        overflow:auto;
        padding-bottom:40px;
        ::-webkit-scrollbar { 
          display: none; 
        }
        thead{
            display:flex;
            tr{
                display:flex;
                width:100%;
                align-items:center;
                height:48px;
                margin:2px 0;
                th{
                    display:flex;
                    flex:1;
                    height:100%;
                    align-items:center;
                    text-align:left;
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 1.26;
                    color: #005993;
                    text-transform:uppercase;
                    :first-child{
                        margin-left:20px;
                    }
                    /*:last-child{
                        margin-right:20px;
                    }*/
                }
                .button-control{
                    display:flex;
                    flex:unset;
                    /*width:50px;*/
                    height:100%;
                }
            }
        }
        tbody{
            display:flex;
            flex-direction:column;
            flex:1;
            border-collapse:separate; 
            border-spacing:0 15px;
            tr{
                display:flex;
                align-items:center;
                width:100%;
                height:48px;
                margin:2px 0;
                background-color:#ffffff;
                border-radius:4px;
                position:relative;
                td{
                    display:flex;
                    flex:1;
                    height:100%;
                    align-items:center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.68;
                    color: #222222;
                    overflow:hidden;
                    :first-child{
                        margin-left:20px;
                    }
                    &:nth-child(5){
                        span{
                            width: 102px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .button-control{
                    display:flex;
                    flex:unset;
                    width:108px;
                    height:100%;
                    .more-button{
                        position:absolute;
                        top:50%;
                        right:8.5px;
                        transform:translate(0,-50%);
                        width:24px;
                        height:24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`
const limitPerPage = 10
export default class TableControl extends Component {
    state={
        data:this.props.data,
        currentPage: 1,
        startIndex: 0,
        endIndex: 10,
        isActive: false
    }

    onPageChange = (page) => {
        let currentPage = page
        this.setState({currentPage})
        this.props.filterUser(this.props.filterUserReducer.listUser, 10, page)
    }
    componentWillReceiveProps(nextProps){
        if(nextProps){
            this.setState({data:nextProps.data})
        }
    }
    renderTitleContent(){
        return this.state.data.title.map((item,i)=>{
            return (
                <th key={i}>
                    {item}
                </th>
            )
        })
    }
    rendeRowTable(){
        return this.state.data.content.map((data,i)=>{
            if(data[3] === 'TELLER'){
                data[3] = 'Giao dịch viên'
            }else if(data[3] === 'OFFADMIN'){
                data[3] = 'Quản trị viên PGD'
            }

            return (
                <tr key={i} className='table-body'>             
                    {this.renderRowContent(data, i)}
                    <td className='button-control'>
                        <img src={ic_more_gray} className='more-button' id={'more_button_id_'+i} alt='ic_more'
                            onClick={()=>{this.onShowControl(i, data)}}/>
                    </td>          
                </tr> 
            )
        })
    }
    onShowControl(index, data){
        let moreBtn=document.getElementById('more_button_id_'+index);
        if(moreBtn){
            let bound=moreBtn.getBoundingClientRect()
            let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let topBound= bound.top + scrollTop
            let leftBound= bound.left + scrollLeft
            let offsetPopup={
                top:topBound+bound.height/2,
                left:leftBound-78+bound.width/2
            }
            this.props.onShowControl(offsetPopup, 'Permission', data)
        }
    }
    renderRowContent(rowData, keyVal){
        console.log('rowData', rowData)
        // if(rowData[3] === 'TELLER'){
        //     rowData[3] = 'Giao dịch viên'
        // }else if(rowData[3] === 'OFFADMIN'){
        //     rowData[3] = 'Quản trị viên PGD'
        // }
        return rowData.map((data,i)=>{
            return (
                <td key={i}>
                    <span>{i===0? formatNumber(keyVal + 1 + (this.state.currentPage-1)*10, 5) : data}</span>
                </td>
            )
        })
    }

    render() {
        const total = this.props.filterUserReducer.total
        return (
            <Bound>
                <table cellSpacing="0" cellPadding="0" className='table' >
                    <thead>
                        <tr className='table-head'>
                            {this.renderTitleContent()}
                            <th className='button-control'>
                            {
                                total > 10 ? 
                                <SortControl
                                currentPage={this.state.currentPage}
                                totalItem={total}
                                limitPerPage={limitPerPage}
                                onChangePage={this.onPageChange}
                                isActive = 'visible'
                                /> 
                                : 
                                <SortControl
                                    currentPage={this.state.currentPage}
                                    totalItem={total}
                                    limitPerPage={limitPerPage}
                                    onChangePage={this.onPageChange}
                                    isActive = 'hidden'
                                /> 
                            }
                            </th>                  
                        </tr>
                    </thead>                
                    <tbody>  
                        {this.rendeRowTable()}
                    </tbody>                
                </table>  
            </Bound>
        )
    }
}