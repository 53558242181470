import React, { Component } from 'react'
import styled from 'styled-components'
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import { color } from '../../../data/store';
import ic_question from '../../../images/Icons_Notification_Question.svg'

const Bound = styled.div`
    padding: 20px 50px 15px 50px;
    background-color: #fafafa;
    width: ${props => props.width || '260px'};
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .row_question{
        display:flex;
        align-items: center;
        justify-content: center;
        .img_question{
            background-image: url(${ic_question});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width: 53px;
            height: 53px;
            object-fit: contain;
        }
    }
    .row_title{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 20px 0 0 0;
        font-family: SVN-Gilroy;
        font-weight: bold;
        line-height: 1.25;
        color: #222222;
        p.title{
            font-weight: normal;
            font-size: 16px;
        }
        p.bold{
            font-size: 16px;
            font-weight: bold;
        }
        p.error{
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            line-height: 1.2;
            color: #f31919;
            text-align: center;
            padding-top: 12px;
        }
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 27px 0 0 0;
        .btn_exit{
            margin: 0 20px 0 0;
        }
    }
`;
export default class PopupDeletePermission extends Component {
    render() {
        const {dataNeedDelete} = this.props
        console.log('aa', dataNeedDelete)
        const ID = this.props.dataNeedDelete[0]
        console.log('ID', ID)
        const ROLE = this.props.dataNeedDelete[3]
        console.log('ROLE', ROLE)

        return (
            <Bound width = {this.props.width}>
                <div className="row_question">
                    <span className='img_question'></span>
                </div>
                <div className="row_title">
                    <p className='title'>{this.props.title}</p>
                    <p className = 'bold'>"{dataNeedDelete[2]}"</p>
                    <p className = 'error'>Tài khoản bị xóa sẽ không thể phục hồi</p>
                </div>
                <p></p>
                <div className="row_btn_confirm">
                    <div className="btn_exit">
                        <ButtonConfirmPopup
                        titleConfirm='HỦY BỎ'
                        bg_color='#f31919'
                        onClick={()=>{this.props.cancelAction()}}
                         />
                    </div>
                    <div className="btn_accept">
                        <ButtonConfirmPopup
                        titleConfirm='XÁC NHẬN'
                        bg_color={color.blue93}
                        onClick={()=>{this.props.acceptAction(ID, ROLE)}}
                         />
                    </div>
                </div>
            </Bound>
        )
    }
}
