import React, { Component } from 'react';
import styled from "styled-components";
//Image
import ic_refresh from '../../../../images/ic_refresh.svg';
//Component
import TransactionCounterItem from './TransactionCounterItem';
// import SingleChoiseDropDownControl from '../Control/SingleChoiseDropDownControl';
import PagingControl from '../Control/PagningControl'
//Data
// import {emptyData} from '../../../../data/data'
import { color } from "../../../../data/store";
// import { transactionTypes } from '../../../../data/dataTransaction';

const Bound = styled.div`
    display: flex;
    flex-direction:column;
    overflow-y: auto;
    flex:1;
    margin-top: 10px;
    font-family: "SVN-Gilroy";
    .transaction-pgd-title {
      display: flex;
      height:50px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: ${color.blue93};
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      :first-child {
          font-size: 11px;
          font-weight: bold;
          line-height: 1.26;
          text-transform: uppercase;
      } 
    }
    .block-transaction{
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
    .scroll{
        display: grid;
        grid-template-columns: 285px 285px 285px 285px;
        grid-gap: 15px;
        justify-content: space-evenly;
        overflow: auto;
        margin-top: 20px;
        &::-webkit-scrollbar {
          width: 0px;
        }
        .counter-transaction{
          box-sizing:border-box;
          width: 285px;
          height: 240px;
          border-radius: 4px;
          background-color: #ffffff;
          padding: 14px 20px;
          font-family: SVN-Gilroy;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .counter-main {
          height: 130px;
          .device-mainConnect{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .counter{
                display: flex;
                justify-content: space-between;
            }
            .service{
                display: flex;
                justify-content: space-between;
            }
            .name{
                font-family: SVN-Gilroy;
                font-size: 11px;
                font-weight: bold;
                line-height: 1.26;
                color: #005993;
            }
            .number{
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.2;
                color: #222222;
            }
          }
            p {
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.68;
                color: #222222;
                &:nth-child(2) {
                    margin-top: 4px;
                }
            }
            .deviceConnect {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .disconnect{
                    font-family: SVN-Gilroy;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: #c8c8c8;
                }
                .data-disconnect{
                  font-family: SVN-Gilroy;
                  font-size: 11px;
                  font-weight: bold;
                  line-height: 1.26;
                  color: #005993;
                  text-transform: uppercase;
                }
                .f-cover{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    .connect{
                      display: flex;
                      align-items: center;
                      width: 70px;
                      .circle{
                          width: 7px;
                          height: 7px;
                          background-color: #0fb11f;     
                          border-radius: 100%; 
                          margin-right: 5px;
                      }
                      p{
                        font-size: 14px;
                        font-weight: 500;
                        font-style: normal;
                        line-height: 1.68;
                        color: #0fb11f;
                        margin: 0;
                      }
                    }
                }
            }
        }

        .counter-bottom {
            margin-left: 5px;
            .dropdown-control{
                width: 240px;
            }
        }
        .load{
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            p.connect-load {
                text-align: right;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.68;
                color: #222222;
            }
        }
        p.connect {
            text-align: right;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            line-height: 1.68;
            color: #0062d7;
            cursor: pointer;
        }
    }
    
    
`
const limitPerPage = 8
class TransactionCounter extends Component {
  state = {
    data: this.props.data,
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
    idShow: ''
  }
  componentWillMount() {
    this.pagnationPage();
  }
  componentWillReceiveProps(n) {
    if (n) {
      this.setState({ data: n.data })
    }
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({
      startIndex,
      endIndex
    })
  }
  getText = (e) => {

  }
  setIdShow = (id) => {
    this.setState({ idShow: id });
  }

  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
  }
  n(n) {
    if (n > 0) {
      return n > 9 ? '' + n : '0' + n
    } else { return 0 }
  }
  renderCounter() {
    let { startIndex, endIndex } = this.state
    return this.state.data.slice(startIndex, endIndex).map((item, i) => {
      return (
        <div className='counter-transaction' key={i}>
          <TransactionCounterItem
          index={i}
          onShowControl={(offset,from,dataReceive)=>this.props.onShowControl(offset,from,dataReceive)}
          counter={item.counterName} isVIP={item.isVIP} />
          <div className='counter-main'>
            <div className='device-mainConnect'>
              <div className="counter">
                <div className="name">SỐ QUẦY</div>
                <div className="number"> {this.n(item.counter)} </div>
              </div>
              <div className="service">
                <div className="name">DỊCH VỤ:</div>
                <div className="number"> {this.n(item.service)} </div>
              </div>
              {   // isConnect : true 
                item.isConnect ? item.deviceConnect.map((name, index) => {
                  return (
                    <div className='deviceConnect' key={index}>
                      <div className='f-cover'>
                        <div className='name'>{name}</div>
                        <div className='connect'>
                          <div className='circle'></div>
                          <p>Connect</p>
                        </div>
                      </div>
                    </div>
                  )
                })
                  :
                  // isConnect: false
                  item.deviceConnect.map((name, index) => {
                    return (
                      <div className='deviceConnect' key={index}>
                        <p className="data-disconnect">{name}</p>
                        <div className='disconnect'>
                          Chưa kết nối ...
                        </div>
                      </div>
                    )
                  })}

            </div>
          </div>
          {/* <div className = 'counter-bottom'>
              <SingleChoiseDropDownControl 
              getText={this.getText}
              title = 'dịch vụ' titleChoise = 'Tất cả dịch vụ' 
              data = {transactionTypes}
              idShow={this.state.idShow}
              setIdShow={this.setIdShow}
              />
          </div> */}
          {
            item.isConnect ?
              <div className='load'>
                <p className='connect-load'>{item.idCounter}</p>
                <img src={ic_refresh} alt='ic_refresh' />
              </div> :
              <p className='connect'>CONNECT ID</p>
          }
        </div>
      )
    })
  }
  render() {
    return (
      <Bound>
        <div className="transaction-pgd-title">
          <div>Danh sách quầy giao dịch</div>
          <PagingControl
            currentPage={this.state.currentPage}
            totalItem={this.state.data.length}
            limitPerPage={limitPerPage}
            onChangePage={page => this.onPageChange(page)}
          />
        </div>
        <div className="block-transaction">
          <div className='scroll'>
            {this.renderCounter()}
          </div>
        </div>

      </Bound>

    );
  }
}

export default TransactionCounter;