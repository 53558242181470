export const dataTrandingRoom = [
  {
    kioskName:'Kiosk 01',
    kioskLCD:'Màn hình chạy vé 01',
    id: "VT7815 - 12321278",
    status: "online",    
    deviceName: "G-KIOSK5010U 42SMT",
  },
  {
    kioskName:'Kiosk 02',
    kioskLCD:'Màn hình chạy vé 02',
    status: "offline",
    deviceStatus:'Chưa kết nối...',
  },
  {
    kioskName:'Kiosk 01',
    kioskLCD:'Màn hình chạy vé 03',
    id: "VT7815 - 12321278",
    status: "online",    
    deviceName: "G-KIOSK5010U 42SMT"
  },
  {
    kioskName:'Kiosk 02',
    kioskLCD:'Màn hình chạy vé 04',
    status: "offline",
    deviceStatus:'Chưa kết nối...',
  },
  {
    kioskName:'Kiosk 01',
    kioskLCD:'Màn hình chạy vé 05',
    id: "VT7815 - 12321278",
    status: "online",    
    deviceName: "G-KIOSK5010U 42SMT"
  },
  {
    kioskName:'Kiosk 02',
    kioskLCD:'Màn hình chạy vé 06',
    status: "offline",
    deviceStatus:'Chưa kết nối...',
  },
  {
    kioskName:'Kiosk 01',
    kioskLCD:'Màn hình chạy vé 07',
    id: "VT7815 - 12321278",
    status: "online",    
    deviceName: "G-KIOSK5010U 42SMT"
  },
  {
    kioskName:'Kiosk 02',
    kioskLCD:'Màn hình chạy vé 08',
    status: "offline",
    deviceStatus:'Chưa kết nối...',
  },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // },
  // {
  //   id: "VT7815 - 12321278",
  //   status: "online",
  //   deviceName: "G-KIOSK5010U 42SMT"
  // },
  // {
  //   status: "offline"
  // }
];
