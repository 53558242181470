import { combineReducers } from 'redux'
import { adsReducer } from './adsReducer';
import { officeReducer } from './officeReducer';
import userReducer from './userReducer/userReducer';
import counterReducer from './counterReducer/counterReducer';
import kioskReducer from './kioskReducer/kioskReducer';

const rootReducer = combineReducers({
  ...adsReducer,
  ...officeReducer,
  userReducer,
  counterReducer,
  kioskReducer
})

export default rootReducer