import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

//Img
import ic_close from '../../../images/ic_close_white.svg';
//Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl'
// data
import { location } from '../../../data/singleChoiseData'
import { filterOffice, alertFillterOffice } from '../../../actions/officeAction';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .popup-filter-bound{
        display:flex;
        flex-direction:column;
        width:200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 60px - 80px - 30px);
        background-color:white;
        position:absolute;
        bottom:0;
        right:-240px;
        transition:right 0.5s;
        align-items:center;
        .div_check{
            width:200px;
            margin: 0 0 20px 0;
        }
        .div_header{
            width:100%;
            margin: 0 0 20px 0;
            display:flex;
            justify-content:space-between;
            align-items: center;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            width: 64px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#ffffff;
            cursor: pointer;
        }
        .close-btn{
            width:20px;
            height:20px;
            cursor: pointer;
        }
        }
    }
`

class PopupBranchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idShow: "",
      data: location,
      typeTrans: '',
      city: '',
      district: '',
      ward: ''
    }
  }

  isMouseEnter = false;

  getText = (type) => text => {
    this.setState({ [type]: text })
  }

  setIdShow = (id) => {
    this.setState({ idShow: id })
  }

  onSubmit = () => {
    let { city, district, ward, typeTrans } = this.state;

    let dataFilter = {}
    if(city) dataFilter["province"] = city;
    if(district) dataFilter["district"] = district;
    if(ward) dataFilter["ward"] = ward;
    // if(typeTrans) dataFilter["officeType"] = typeTrans;
    if(typeTrans){
      if (typeTrans === 'Phòng giao dịch') {
        dataFilter.officeType = 'TRADING'
      } else if (typeTrans === 'Chi nhánh') {
        dataFilter.officeType = 'BRANCH'
      } else {
        dataFilter.officeType = 'SAVING'
      }
    }
    console.log('dataFilter',dataFilter);
    if (Object.keys(dataFilter).length !== 0) {
      this
        .props
        .filterOffice(dataFilter, 10, 1)
        this.props.alertContent('');
        // redux mapdistpatch
        // redux mapdistpatch
        // redux mapdistpatch
      this
        .props
        .onClose()
    } 
    else {
      this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
      return
    }
    this.props.outPopupAlert();
  }

  render() {
    const officeTrans = this.getText("typeTrans");
    const cityTrans = this.getText("city");
    const districtTrans = this.getText("district");
    const wardTrans = this.getText("ward");
    return (
      <Bound
        onMouseDown={() => {
          if (!this.isMouseEnter) {
            this
              .props
              .onClose()
          }
        }}>
        <div
          className='popup-filter-bound'
          id='filter_branch_id'
          onMouseEnter={() => {
            this.isMouseEnter = true
          }}
          onMouseLeave={() => {
            this.isMouseEnter = false
          }}>
          <div className="div_header">
            <img
              src={ic_close}
              alt='close'
              className='close-btn'
              onClick={() => {
                this
                  .props
                  .onClose()
              }} />
            <div onClick={() => this.onSubmit()} className='title'>FILTER</div>
          </div>
          <div className="div_check">
            <SingleChoiseDropDownControl
              id="selectCity"
              getText={cityTrans}
              data={this.state.data.city}
              titleChoise='Chọn thành phố'
              title='thành phố'
              idShow={this.state.idShow}
              setIdShow={this.setIdShow} />
          </div>
          <div className="div_check">
            <SingleChoiseDropDownControl
              id="selectDistrict"
              getText={districtTrans}
              data={this.state.data.district}
              titleChoise='Chọn quận/ huyện'
              title='quận / huyện'
              idShow={this.state.idShow}
              setIdShow={this.setIdShow} />
          </div>
          <div className="div_check">
            <SingleChoiseDropDownControl
              id="selectTown"
              getText={wardTrans}
              data={this.state.data.ward}
              titleChoise='Chọn phường/ xã'
              title='phường / xã'
              idShow={this.state.idShow}
              setIdShow={this.setIdShow} />
          </div>
          <div className="div_check">
            <SingleChoiseDropDownControl
              id="selectOffice"
              getText={officeTrans}
              data={this.state.data.office}
              titleChoise='Chọn loại VP'
              title=' loại vp giao dịch'
              idShow={this.state.idShow}
              setIdShow={this.setIdShow} />
          </div>
        </div>
      </Bound>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  filterOffice: (dataFilter, pageSize, pageNumber) => dispatch(filterOffice(dataFilter, pageSize, pageNumber)),
  alertContent: (data) => dispatch(alertFillterOffice(data)),
})

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(PopupBranchFilter)