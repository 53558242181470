export const color={
    bluef7:'#7ed3f7',
    blue93:'#005993',
    red49:'#d71249',
}
export const user = [
    {
        username: 'admin',
        password: '12345',
        fullname: 'Administrator',
        email: 'admin@admin123.com',
        permission: 'adminRoot',
        avatarImg: 'https://picsum.photos/200'
    },
    {
        username: 'admin2',
        password: '12345',
        fullname: 'Admin PGD',
        email: 'abcd@abcd.com',
        permission: 'adminBranch',
        avatarImg: 'https://picsum.photos/200'
    },
    {
        username: 'admin3',
        password: '12345',
        fullname: 'Admin PGD',
        email: 'abcd@abcd.com',
        permission: 'adminBranch',
        avatarImg: 'https://picsum.photos/200'
    },
    {
        username: 'admin4',
        password: '12345',
        fullname: 'Admin PGD',
        email: 'abcd@abcd.com',
        permission: 'adminBranch',
        avatarImg: 'https://picsum.photos/200'
    }
]
export const users = [
    {
        id:'VT3001',
        username: 'anh@vietinbank.vn',
        password: '12345',
        fullname: 'Nguyễn Ngọc Anh',
        email: 'anh@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Bến Thành',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3002',
        username: 'duy@vietinbank.vn',
        password: '12345',
        fullname: 'Lê Quang Duy',
        email: 'duy@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Bến Thành',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3003',
        username: 'khoa@vietinbank.vn',
        password: '12345',
        fullname: 'Ngô Anh Khoa',
        email: 'khoa@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Bến Thành',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3004',
        username: 'phu@vietinbank.vn',
        password: '12345',
        fullname: 'Trình Lê Thiên Phú',
        email: 'phu@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Trần Hưng Đạo',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3005',
        username: 'tuyen@vietinbank.vn',
        password: '12345',
        fullname: 'Hà Thị Ngọc Tuyền',
        email: 'tuyen@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Trần Hưng Đạo',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3006',
        username: 'tunganh@vietinbank.vn',
        password: '12345',
        fullname: 'Nguyễn Tùng Anh',
        email: 'tunganh@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Trần Hưng Đạo',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3007',
        username: 'huy@vietinbank.vn',
        password: '12345',
        fullname: 'Nguyễn Quang Huy',
        email: 'huy@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Bến Nghé',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3008',
        username: 'cong@vietinbank.vn',
        password: '12345',
        fullname: 'Vũ Thành Công',
        email: 'cong@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Bến Nghé',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3009',
        username: 'toan@vietinbank.vn',
        password: '12345',
        fullname: 'Trương Văn Toàn',
        email: 'toan@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Bến Nghé',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3010',
        username: 'duc@vietinbank.vn',
        password: '12345',
        fullname: 'Nguyễn Thanh Đức',
        email: 'duc@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Hóc Môn',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3011',
        username: 'chuong@vietinbank.vn',
        password: '12345',
        fullname: 'Hoàng Trọng Chương',
        email: 'chuong@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Hóc Môn',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3012',
        username: 'duong@vietinbank.vn',
        password: '12345',
        fullname: 'Dương Minh Dương',
        email: 'duong@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Bến Thành',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3013',
        username: 'duyen@vietinbank.vn',
        password: '12345',
        fullname: 'Võ Thị Mỹ Duyên',
        email: 'duyen@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Hóc Môn',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3014',
        username: 'phuoc@vietinbank.vn',
        password: '12345',
        fullname: 'Lê Hoàng Phước',
        email: 'phuoc@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Tân Sơn Nhất',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3015',
        username: 'thinh@vietinbank.vn',
        password: '12345',
        fullname: 'Mai Văn Thịnh',
        email: 'thinh@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Tân Sơn Nhất',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3016',
        username: 'hoai@vietinbank.vn',
        password: '12345',
        fullname: 'Lê Hoài',
        email: 'hoai@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Tân Sơn Nhất',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3017',
        username: 'ha@vietinbank.vn',
        password: '12345',
        fullname: 'Thúy Hà',
        email: 'ha@vietinbank.vn',
        permission: 'mod',
        branch:'PGD Sư Vạn Hạnh',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
    {
        id:'VT3018',
        username: 'thamoanh@vietinbank.vn',
        password: '12345',
        fullname: 'Thẩm Oanh',
        email: 'thamoanh@vietinbank.vn',
        permission: 'counter',
        branch:'PGD Sư Vạn Hạnh',
        avatarImg: 'https://picsum.photos/200',
        modifyDate:'18-05-19'
    },
]

export const branchs=[
    {
        branchName:'Chi nhánh Hồ Chí Minh',
        rooms:[
            {
                name:'PGD Bến Thành',
                address:'Số 19 Phan Chu Trinh, Quận 1, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
            {
                name:'PGD Nguyễn Du',
                address:'Số 65, Nguyễn Du, Quận 1, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
            {
                name:'PGD Trường Sơn',
                address:'Số 34 Phan Đình Giót, Quận Tân Bình, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
            {
                name:'PGD Vinhomes Tân Cảng',
                address:'Tầng trệt, tầng 1 L2.SH01, tòa nhà Landmark 2, Vinhomes Central Park, số 720A đường Điện Biên Phủ, phường 22, quận Bình Thạnh, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
            {
                name:'PGD Thành Thái',
                address:'Số 145 Thành Thái, Quận 10, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
            {
                name:'PGD  Tân Sơn Nhất',
                address:'Tầng 1, sảnh ga đi quốc Nội, sân bay Tân Sơn Nhất, quận Tân Bình, TP. Hồ Chí Minh',
                counter:0,
                kiosk:0
            },
        ]
    },
    {
        branchName:'Chi nhánh Đông Sài Gòn',
        rooms:[
            
        ]
    },
    {
        branchName:'Chi nhánh Tây Sài Gòn',
        rooms:[
            
        ]
    },
    {
        branchName:'Chi nhánh Nam Sài Gòn',
        rooms:[
            
        ]
    },
]

export const dataCounter = [
    {
        counterName:'Quầy mặc định',
        isConnect:true,
        isVIP:true,
        idCounter: 'VT7815 - 12321278',
        counter: 4,
        service: 5,
        deviceConnect: [
            'THIẾT BỊ QUẦY',
            'THIẾT BỊ HỖ TRỢ GDV'
        ],

    },
    // {
        // counterName:'Quầy số 2',
    //     isConnect: false,
    //     idCounter: 'Connect ID',
    //     deviceConnect: [
    //         'Thiết bị quầy',
    //         'Thiết bị hỗ trợ GDV'
    //     ]
    // },
    // {
        // counterName:'Quầy số 3',
    //     isConnect: false,
    //     idCounter: 'Connect ID',
    //     deviceConnect: [
    //         'Thiết bị quầy',
    //         'Thiết bị hỗ trợ GDV',
    //     ]
    // },
    // {
        // counterName:'Quầy số 4',
    //     isConnect: false,
    //     idCounter: 'Connect ID',
    //     deviceConnect: [
    //         'Thiết bị quầy',
    //         'Thiết bị hỗ trợ GDV'
    //     ]
    // },
    // {
        // counterName:'Quầy số 5',
    //     isConnect: true,
    //     idCounter: 'VT7815 - 12321278',
    //     deviceConnect: [
    //         'DESKTOP-2SVV2II...',
    //         'Huawei T812 Think...'
    //     ]
    // },
    // {
        // counterName:'Quầy số 6',
    //     isConnect: false,
    //     idCounter: 'Connect ID',
    //     deviceConnect: [
    //         'Thiết bị quầy',
    //         'Thiết bị hỗ trợ GDV'
    //     ]
    // },
    // {
        // counterName:'Quầy số 7',
    //     isConnect: true,
    //     idCounter: 'VT7815 - 12321278',
    //     deviceConnect: [
    //         'DESKTOP-2SVV2II...',
    //         'Huawei T812 Think...'
    //     ]
    // },
]