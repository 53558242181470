import React, { Component } from 'react'
import styled from 'styled-components';
// Images
import ic_close from '../../../images/ic_close_white.svg'
// Component
import InputTextControl from '../MainScreen/Control/InputTextControl';
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
// Data
import { color } from '../../../data/store'
import { location } from '../../../data/singleChoiseData';
import { types } from '../../../data/dataBranch';

const Bound = styled.div`
    padding: 16px 14px 24px 14px;
    width: 500px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fafafa;
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
          font-size:20pt;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupMainBody{
        padding: 20px 5px 0 5px;
        .type-transaction {
          margin: 0 0 40px 0;
          width: 280px;
            .titleTypeTrans{
                font-family: 'SVN-Gilroy';
                font-size: 11px;
                font-weight: bold;
                line-height: 1.26;
                color: #005993;
                text-transform: uppercase;
            }
            select.dropdown-control{
                width: 280px;
                min-width: 280px;
                background-position: top 20px right 17px;
            }
        }
        .info_Address{
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            img{
                position:absolute;
                /* right:0; */
            }
            .city{
                width: 32%;
                margin-right: 4%;
            }
            .district{
                width: 28%;
            }
            .ward{
                width: 32%;
                margin-left: 4%;
            }
        }
        .btn_confirm{
            margin: 24px 0 0 0;
            float:right;
        }
    }
`

export default class PopupTransaction extends Component {

  state = {
    data: location,
    NameTransactionRoom: '',
    AddressTransactionRoom: '',
    typeTrans: '',
    city: '',
    district: '',
    ward: '',
    idShow: ""
  }
  getBranchName(e) {
    this.setState({
      NameTransactionRoom: e
    })
  }
  getAddress(e) {
    this.setState({
      AddressTransactionRoom: e
    })
  }

  getText = (type) => text => {
    this.setState({ [type]: text })
  }

  setIdShow = (id) => {
    this.setState({ idShow: id })
  }

  onSubmit = () => {
    if (this.props.dataNeedModify && this.state) {
      console.log(this.state);
      let data = this.props.dataNeedModify;
      let id = data.ID;
      this.setState({

        NameTransactionRoom:this.state.NameTransactionRoom?this.state.NameTransactionRoom:data.Name,

        AddressTransactionRoom:this.state.AddressTransactionRoom?this.state.AddressTransactionRoom:data.Address,

        typeTrans:this.state.typeTrans?this.state.typeTrans:data.OfficeType,

        city:this.state.city?this.state.city:data.Province,

        district:this.state.district?this.state.district:data.District,

        ward:this.state.ward?this.state.ward:data.Ward,
       
      },()=>{
        let { NameTransactionRoom, AddressTransactionRoom, city, district, ward, typeTrans } = this.state;
        if (NameTransactionRoom && AddressTransactionRoom && city && district && ward && typeTrans !== '') {
       if (typeTrans === 'Phòng giao dịch') {
          typeTrans = 'TRADING'
        } else if (typeTrans === 'Chi nhánh') {
          typeTrans = 'BRANCH'
        } else {
          typeTrans = 'SAVING'
        }
          let data = {
            id,NameTransactionRoom,AddressTransactionRoom,city,district,ward,typeTrans
          }
          this.props.handleOnClick(data);
          this.props.outPopup();
          // console.log(data);
          // console.log('Update rồi nha');
          
        }else {
          console.log('Update k dc');
          // this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
      }
      });
    }else {
      let { NameTransactionRoom, AddressTransactionRoom, city, district, ward, typeTrans } = this.state;
      if (NameTransactionRoom && AddressTransactionRoom && city && district && ward && typeTrans !== '') {
        if (typeTrans === 'Phòng giao dịch') {
          typeTrans = 'TRADING'
        } else if (typeTrans === 'Chi nhánh') {
          typeTrans = 'BRANCH'
        } else {
          typeTrans = 'SAVING'
        }
        this.props.handleOnClick(NameTransactionRoom, AddressTransactionRoom, city, district, ward, typeTrans);
        this.props.outPopup();
        console.log('Thêm mới dc rồi');
        
      }else {
        console.log('Thêm k dc');
        // this.props.alertPopup('Vui lòng điền đầy đủ thông tin')
    }
    }
  }

  render() {
    // console.log('dataNeedModify',this.props.dataNeedModify)
    const officeTrans = this.getText("typeTrans");
    const cityTrans = this.getText("city");
    const districtTrans = this.getText("district");
    const wardTrans = this.getText("ward");
    const { dataNeedModify } = this.props;  
    
    if (dataNeedModify) {
      var checkType = types.find(item => item.type === dataNeedModify.OfficeType);
    }
    return (
      <Bound>
        <div className="popupHeader">
          <h4 className='title'>{this.props.titleHeader}</h4>
          <span onClick={() => this.props.outPopup()} className='ic_close'></span>
        </div>
        <div className="popupMainBody">
          <div className='type-transaction'>
            <p className='titleTypeTrans'>loại VP giao dịch</p>
            <SingleChoiseDropDownControl
              id="selectTypeTrans"
              idShow={this.state.idShow}
              setIdShow={this.setIdShow}
              titleChoise='Chọn loại VP'
              getText={officeTrans}
              data={this.state.data.office}
              defaultValue={dataNeedModify?checkType.name:''}
              />
          </div>
          <InputTextControl title='TÊN PHÒNG GIAO DỊCH'
            getText={value => this.getBranchName(value)}
            placeHolder='Nhập tên phòng giao dịch'
            defaultValue={dataNeedModify ? dataNeedModify.Name : ''}
          />
          <InputTextControl title='ĐỊA CHỈ'
            getText={value => this.getAddress(value)}
            placeHolder='Nhập địa chỉ'
            defaultValue={dataNeedModify ? dataNeedModify.Address :''}
          />
          <div className="info_Address">
            <div className="city">
              <SingleChoiseDropDownControl
                id="selectCity"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={cityTrans}
                titleChoise='Chọn thành phố'
                data={this.state.data.city}
                title='THÀNH PHỐ'
                defaultValue={dataNeedModify ? dataNeedModify.Province : ''}  
              />
            </div>
            <div className="district">
              <SingleChoiseDropDownControl
                id="selectDistrict"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={districtTrans}
                titleChoise='Chọn quận/ huyện'
                data={this.state.data.district}
                title='QUẬN/ HUYỆN'
                defaultValue={dataNeedModify ? dataNeedModify.District : ''}  
              />
            </div>
            <div className="ward">
              <SingleChoiseDropDownControl
                id="selectWard"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                getText={wardTrans}
                titleChoise='Chọn phường/xã'
                data={this.state.data.ward}
                title='PHƯỜNG/ XÃ'
                defaultValue={dataNeedModify ? dataNeedModify.Ward : ''}
              />

            </div>
          </div>
          <div className="btn_confirm">
            <ButtonConfirmPopup bg_color={color.blue93}
              onClick={() => this.onSubmit()}
              titleConfirm='HOÀN TẤT' />
          </div>
        </div>
      </Bound>
    )
  }
}
