import React, { Component } from 'react'
import {connect} from "react-redux";
import styled from 'styled-components'
import { user } from '../../data/store';

import logo_viettin from '../../images/logo_vietinbank.svg'
import { webVersion } from '../../data/data';
//image
import linear from '../../images/Pattern_VTB-03.svg'
import { loginUser, filterUser, addUser } from '../../actions/userAction';


const Bound = styled.div`
    background-color: #7ED3F7;
    width:100%;
    height: 100vh;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .loginContainer{
        .rowHeader{
            width: 100%;
            height: 60px;
            background-color: #ffffff;
            display:flex;
            align-items: center;
            .logoVietinBank{
                margin: 0 0 0 25px;
            }
        }
        .rowLinear{
            width: 100%;
            height: 12px;
            background-image: url(${linear});
        }
        .rowMain{
            width: 100%;
            height: 100%;
        .col_login{
            padding: 32px 40px 24px 40px;
            width: 320px;
            height: 404px;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            background-color: #ffffff;
            position:absolute;
            top: 150px;
            left: 200px;
            .title{
                font-size: 24px;
                font-weight: bold;
                line-height: 1.08;
                color: #005993;
                display: flex;
                align-items: center;
                display:flex;
                justify-content: center;
            }
            .content{
                margin: 28px 0 0 0;
                display:flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.13;
                color: #222222;
            }
            .form_login{
                margin: 25px 0 0 0;
                label{
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 1.26;
                    color: #005993;
                }
                input{
                    margin: 5px 0 20px 0;
                    width: 300px;
                    height: 24px;
                    border-radius: 4px;
                    background-color: #fafafa;
                    border: none;
                    outline: none;
                    padding: 10px;
                    &::placeholder{
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.68;
                        color: #222222;
                        opacity: 0.25;
                    }
                }
                .blockBtn{
                    margin: 20px 0 0 0;
                    justify-content: center;
                    display: flex;
                    .btn_login{
                        width: 120px;
                        height: 36px;
                        border-radius: 18px;
                        background-color: #7ed3f7;
                        display:flex;
                        border:none;
                        cursor: pointer;
                        display:flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: bold;
                        color: #005993;
                        outline:none;
                    }
                }
                .version{
                    margin: 40px 0 0 0;
                    display:flex;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.42;
                    color: #222222;
                }
            }

        }
        }
    }


`;

  class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userStore: user,
      userTmp: '',
      passTmp: ''
    }
    this.oldUser = ''
    this.oldPass = ''

  }
  onClear = () => {
    this.setState({ userTmp: '', passTmp: '' })
  }
  onEnter = e => {
    if (e.keyCode === 13) {
      this.onSubmit();
      this.onClear();
    }
  };
  handleUserInput = (e) => {
    //check valid value input
    let regex = e
      .target
      .value
      .replace(/\s/g, "")
    //true =>
    this.oldUser = regex
    this.setState({ userTmp: this.oldUser })
    //false =>
  }

  handlePassInput = (e) => {
    let regex = e
      .target
      .value
      .replace(/\s/g, "")
    this.oldPass = regex
    this.setState({ passTmp: this.oldPass })
  }
  onSubmit = () => {
    let { userTmp, passTmp, userStore } = this.state;
    // let { isLogin } = this.props;

    // let check = userStore.find(item => item.username === this.state.userTmp);

    // if (check) {
    //  if (userTmp === check.username) {
    //     if (passTmp === check.password) {
    //       isLogin(check.username, check.fullname, check.email, check.permission, check.avatarImg, true);
    //     } else {
    //       let contentAlert = 'Sai mật khẩu'
    //       this.props.alertPopup(contentAlert)
    //       // alert('sai mk')
    //     }
    //   } else if (userTmp !== check.username) {
    //     alert('sai tk hoặc mk')
    //     this.onClear();
    //   }
    // } else {
    //   let contentAlert = 'Tài khoản không tồn tại'
    //   this.props.alertPopup(contentAlert)
    //   this.onClear();
    // }

    if (!userTmp.trim() || !passTmp.trim()){
      this.props.alertPopup("Email và mật khẩu không được để trống")
      return;
    }
    this.props.loginUser(userTmp, passTmp)
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.error !== this.props.error){
      if (this.props.error && this.props.error.response && this.props.error.response.status === 400) {
        console.clear();
        this.props.alertPopup("Email hoặc mật khẩu không đúng")
      }
    }
  }
  

  render() {
    return (
      <Bound>
        <div className="loginContainer">
          <div className="rowHeader">
            <div className="logoVietinBank">
              <img src={logo_viettin} alt="logoViettinBank" />
            </div>
          </div>
          <div className="rowLinear"></div>
          <div className="rowMain">
            <div className='col_login'>
              <div className="title">
                <p>SDK Vietinbank Counter</p>
              </div>
              <div className="content">
                Đăng nhập hệ thống
                            </div>
              <div className="form_login">
                <label>EMAIL</label>
                <input
                  name='userName'
                  onChange={(e) => this.handleUserInput(e)}
                  onKeyUp={(e) => this.onEnter(e)}
                  className='userName'
                  value={this.state.userTmp || ''}
                  placeholder='Nhập tên email'></input>
                <label>MẬT KHẨU</label>
                <input
                  type='password'
                  onChange={(e) => this.handlePassInput(e)}
                  onKeyUp={(e) => this.onEnter(e)}
                  className='password'
                  value={this.state.passTmp || ''}
                  placeholder='Nhập mật khẩu'></input>
                <div className='blockBtn'>
                  <button onClick={() => this.onSubmit()} className='btn_login'>ĐĂNG NHẬP</button>
                </div>
                <div className='version'>
                  Phiên bản v{webVersion}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Bound>
    )
  }
  
  componentDidMount() {
    // this.props.filterUser();
    // this.props.addUser()
  }
  
}

export default LoginPage