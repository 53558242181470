export let location = {
    city:['Hồ Chí Minh', 'Hà Nội', 'Hải Phòng', 'Đà Nẵng', 'Thái Nguyên', 'Bình Dương', 'Huế'],
    district:['Tân Bình','Quận 10','Quận 1', 'Hoàng Mai', 'Đống Đa', 'Lê Chân'],
    ward:['1','2','3','4','5','6','7'],
    office: ['Phòng giao dịch', 'Chi nhánh', 'Gửi tiết kiệm']
}

export const setPermissionSingleChoise = {
    rank:[
        {   
            type: 'TELLER',
            name: 'Giao dịch viên'
        },
        {   
            type: 'OFFADMIN',
            name: 'Quản trị viên PGD'
        }
    ],
    // rank:[
    //     'Giao dịch viên', 'Quản trị viên PGD'
    // ],
    roomTrans: ['PGD Hồ Chí Minh', 'PGD Hà Nội', 'PGD Hải Phòng', 'PGD Đà Nẵng', 'PGD Huế', 'PGD Bình Dương']
} 