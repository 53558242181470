import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import PopupAdvertiseRoot from './PopupAdvertiseRoot';

import { popupType } from '../../../data/data'
import PopupTransaction from './PopupTransaction';
import PopupConfirm from './PopupConfirm';
import PopupNewImage from './PopupNewImage';
import PopupAddBranch from './PopupAddBranch';
import PopupSuccess from './PopupSuccess';
import PopupAddPermission from './PopupAddPermission';
import PopupControl from './PopupControl';

import FlowAddAdvertisement from './FlowAddAdvertisement';

/* import action */
import { createOffice, updateOffice, deleteOffice, getAllOffice, filterOffice } from '../../../actions/officeAction';
import { createAds, getAds, updateAds, deleteAds, setStatusAds } from '../../../actions/adsAction';
import { deleteUser, filterUser, addUser, updateUser } from '../../../actions/userAction';
import PopupAccountPermission from './PopupAccountPermission';
import PopupDeletePermission from './PopupDeletePermission';
import { formatSize } from '../../../tools';
import FlowOfficeCheckNumber from './FlowOfficeCheckNumber';
import PopupAddCounter from './PopupAddCounter';
import { typeAds } from '../../../data/advertisementData';
import { types } from '../../../data/dataBranch';
// import PopupAlert from './PopupAlert';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    background-color:${props => props.nonOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.6)'} ;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
`

class PopupContainer extends Component {
  constructor(props) {
    super(props)
    this.isMouseEnterPopup = false;
    this.state = {
      nonOverlay: false,
      content: null,
    }
  }
  onClosePopup = () => {
    this.props.closePopup()
  }
  componentDidMount() {
    this.getPopupContent(this.props.popupRef)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) { }
  }
  getData = data => {
    let Data = { data }
    Object.assign(this.props.popupRef.data, Data)
    this.props.getData(this.props.popupRef)
  }
   
   
  // -- User
  deleteUser = (id, role) => {
    if(id){
      this.props.deleteUser(id)
      if(role === 'ADMIN') {
        this.props.filterUser('ADMIN', 10, 1)
      }else if(role === 'Giao dịch viên') {
        this.props.filterUser('TELLER', 10, 1)
      }else if(role === 'Quản trị viên PGD'){
        this.props.filterUser('OFFADMIN', 10, 1)
      }
      this.onClosePopup()
    }
  }
  
  createUser = (data) => {
      this.props.createUser(data)
  }

  updateUser = (dataUpdate) => {
    this.props.updateUser(dataUpdate)
    if(dataUpdate.Role === 'ADMIN') {
      this.props.filterUser('ADMIN', 10, 1)
    }else if(dataUpdate.Role === 'TELLER') {
      this.props.filterUser('TELLER', 10, 1)
    }else if(dataUpdate.Role === 'OFFADMIN'){
      this.props.filterUser('OFFADMIN', 10, 1)
    }
  }

// delete office + advertisement
  deleteItem = async (dataNeedDelete) => {
    // console.log(dataNeedDelete.TypeAds);
    if (dataNeedDelete.ID && dataNeedDelete.TypeAds) {
      await this.props.deleteAds(dataNeedDelete.ID);
      this.onClosePopup();
      let checkTypeAds = typeAds.find(item => item.type === dataNeedDelete.TypeAds)
      if (dataNeedDelete.TypeAds === checkTypeAds.type) {
        await this.props.getAds(checkTypeAds.type, 10, 1);
      } else if (dataNeedDelete.TypeAds === checkTypeAds.type){
        await this.props.getAds(checkTypeAds.type, 10, 1);
      } else {
        await this.props.getAds(checkTypeAds.type, 10, 1);
      }
    }
    if (dataNeedDelete.ID && dataNeedDelete.OfficeType) {
      if (dataNeedDelete.NumOfCounter === 0 && dataNeedDelete.NumOfKiosk === 0) {
        this.props.onShowRemoveBranchError({dataNeedDelete})
      }else {
        this.props.deleteOffice(dataNeedDelete.ID);
        this.props.filterOffice({},10,1)
        this.onClosePopup();
      }
    }
  }

  updateKiosK = (id, dataChange) => {
    if (id && dataChange) {
      this.props.updateAds(id, dataChange)
      let checkTypeAds = typeAds.find(item => item.type === dataChange.TypeAds)
    if (dataChange.type === checkTypeAds.type) {
      this.props.getAds(checkTypeAds.type, 20, 1);
    }else if(dataChange.type === checkTypeAds.type){
      this.props.getAds(checkTypeAds.type, 20, 1);
    }else {
      this.props.getAds(checkTypeAds.type, 20, 1);
    }
      this.onClosePopup();
    }
  }
  updateOffice = (data) => {
    // console.log('111111111111111111111111111111111111111',data);
    let dataUpdate = {
      ID: data.id,
      Name: data.NameTransactionRoom,
      Address: data.AddressTransactionRoom,
      Province:data.city,
      District:data.district,
      Ward:data.ward,
      OfficeType:data.typeTrans
    }
    let checkTypeOffice = types.find(item => item.type === data.typeTrans )
    this.props.updateOffice(dataUpdate);
    if (data.typeTrans === checkTypeOffice.type) {
       this.props.filterOffice(checkTypeOffice.type,10,1);
     }else if (data.typeTrans === checkTypeOffice.type){
        this.props.filterOffice(checkTypeOffice.type,10,1);
     }else {
        this.props.filterOffice(checkTypeOffice.type,10,1);
    }
  }


  addCounter = (test) => {
    console.log('test',test);
  }

  getPopupContent(popupRef) {
    let content = null;
    switch (popupRef.type) {

      case popupType.configAdvertiseTable:
        {
          content = <PopupAdvertiseRoot
            title={popupRef.data.title}
            titleHeader='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.configTransaction:
        {
          content = <PopupTransaction
            alertPopup = {this.props.alertPopup}
            titleHeader={popupRef.data.title}
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={() => {
              this.onClosePopup()
            }}
            handleOnClick={
              popupRef.data.dataNeedModify ?
                (data) => { 
                  this.updateOffice(data)
                } : this.props.createOffice
            }
          />
          break;
        }
      case popupType.configAddBranch: {
        content = <PopupAddBranch
          // typeAdvert={popupRef.data.typeAdvert}
          titleHeader={popupRef.data.titleHeader}
          title = {popupRef.data.title}
          placeHolder = {popupRef.data.placeHolder}
          dataNeedModify={popupRef.data.dataNeedModify}          
          outPopup={() => {
            this.onClosePopup()
          }}
          getData={(data) => this.getData(data)}
          handleOnClick={this.props.createOffice}
        />
        break;
      }
      case popupType.configSuccess: {
        content = <PopupSuccess
          titleHeader='Tạo tài khoản thành công'
          outPopup={() => {
            this.onClosePopup()
          }}
        />
        break;
      }
      case popupType.configAddPermission: {
        content = <PopupAddPermission
          titleHeader='Thêm mới tài khoản'
          contentTitle='Tìm kiếm tài khoản Vietinbank'
          outPopup={() => {
            this.onClosePopup()
          }}
          addPermissionUser = {this.createUser}
        />
        break;
      }
      case popupType.configConfirm:
        {
          content = <PopupConfirm
            title={popupRef.data.title}
            dataNeedDelete={popupRef.data.dataNeedDelete}
            // outPopup={() => {
            //   this.onClosePopup()
            // }}
            nameData={popupRef.data.dataNeedDelete.Name}
            nameKiosk={popupRef.data.nameKiosk}
            nameLCD={popupRef.data.nameLCD}
            contentDelete={popupRef.data.contentDelete}
            width={popupRef.data.widthPopup}
            acceptAction={this.deleteItem}
            cancelAction={() => {this.onClosePopup()}}
          />
          break;
        }
      case popupType.configAdvertiseNewImage:
        {
          content = <PopupNewImage
            title='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.popupControlType:
        {
          content = <PopupControl
            offsetPopup={popupRef.data.offsetPopup}
            from={popupRef.data.from}
            dataReceive={popupRef.data.dataReceive}
            onModify={this.props.onModify}
            onRemove={
              this.props.onRemoveConfirm
            } />
          this.setState({ nonOverlay: true })
          break;
        }
      case popupType.configAdvertisementPopup:
        {
          content = <FlowAddAdvertisement
            typeAdvert={popupRef.data.typeAdvert}
            outPopup={() => this.onClosePopup()}
            handleOnSubmit={this.props.createAds}
            handleReLoadData={this.props.getAds}
          />
          break;
        }
      case popupType.configFlowCheckOffice:
        {
          content = <FlowOfficeCheckNumber
            // typeAdvert={popupRef.data.typeAdvert}
            contentDelete={popupRef.data.contentDelete}
            branchName={popupRef.data.dataShow.dataNeedDelete.Name}
            NumOfCounter={popupRef.data.dataShow.dataNeedDelete.NumOfCounter}
            NumOfKiosk={popupRef.data.dataShow.dataNeedDelete.NumOfKiosk}
            outPopup={() => this.onClosePopup()}
          />
          break;
        }
      case popupType.configAccountPermission:
        {
          content = <PopupAccountPermission
            from={popupRef.data.form}
            titleHeader='Thông tin tài khoản'
            outPopup={() => this.onClosePopup()}
            dataNeedModify={popupRef.data.dataNeedModify}
            dataNeedDelete={popupRef.data.dataNeedDelete}
            handleOnClick={
              popupRef.data.dataNeedModify ? () => { } : this.props.createOffice
            }
            // updatePermissionUser = {this.updateUser}
            updatePermissionUser={
              popupRef.data.dataNeedModify ?
                (data) => { 
                  this.updateUser(data)
                } : ''
            }
            />
          break;
        }
      case popupType.configDeletePermission:
        {
          content = <PopupDeletePermission
              from = {popupRef.data.form}
              title={popupRef.data.title}
              dataNeedDelete={popupRef.data.dataNeedDelete}
              cancelAction = {() => this.onClosePopup()}
              titleHeader = 'Thông tin tài khoản'
              outPopup={() => this.onClosePopup()}
              dataNeedModify = {popupRef.data.dataNeedModify}
              handleOnClick = {
                  popupRef.data.dataNeedModify ? () => {} : this.props.createOffice
              }
              acceptAction={(id, role) => this.deleteUser(id, role)}
            />
          break;
        }
      case popupType.configAdvertiseKiosk:
        {
          // console.log(popupRef.data.dataNeedModify)
          content = <PopupAdvertiseRoot
            title={popupRef.data.dataNeedModify.titlePopup}
            TitleAds={popupRef.data.dataNeedModify.titleAds}
            descTitle={popupRef.data.dataNeedModify.descTitle}
            titleConfirm={popupRef.data.titleConfirm}
            formatSize={formatSize}
            imgInfo={
              {
                url: popupRef.data.dataNeedModify.fileSrc,
                name: popupRef.data.dataNeedModify.FileName,
                width: popupRef.data.dataNeedModify.width,
                height: popupRef.data.dataNeedModify.height,
                size: popupRef.data.dataNeedModify.fileSize,

              }
            }
            handleOnSubmit={this.props.updateAds}
            handleReLoadData={this.props.getAds}
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={() => {
              this.onClosePopup()
            }}
            />
          break;
        }
        case popupType.configAddCounter:
          {
            content = <PopupAddCounter
                outPopup={() => this.onClosePopup()}
                dataNeedModify = {popupRef.data.dataNeedModify}
                addCounter = {this.addCounter}
                titleHeader={popupRef.data.titleHeader}
                acceptAction={() => this.acceptActionCounter()}
              />
            break;
          }
        default:
          break;
    }
    this.setState({ content })
  }

  render() {
    return (
      <Bound nonOverlay={this.state.nonOverlay}

        onMouseDown={() => {
          if (!this.isMouseEnterPopup) {
            this.onClosePopup();
          }
        }}
      >
        <div
          onMouseEnter={() => {
            return this.isMouseEnterPopup = true
          }}
          onMouseLeave={() => {
            return this.isMouseEnterPopup = false
          }}
        >
          {this.state.content}
        </div>
      </Bound>
    )
  }
}
const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  //// office
  createOffice: (name, address, province, district, ward, officeType) => dispatch(createOffice(name, address, province, district, ward, officeType)),
  filterOffice:(datafilter,pageSize,pageNumber)=>dispatch(filterOffice(datafilter,pageSize,pageNumber)),
  getAllOffice :() => dispatch(getAllOffice()),
  updateOffice: (dataUpdate) => dispatch(updateOffice(dataUpdate)),
  deleteOffice:(id) => dispatch(deleteOffice(id)),
  //// ads
  createAds: (title, desc, upload, typeAds, active, applyOffice = undefined) => dispatch(createAds(title, desc, upload, typeAds, active, applyOffice)),
  getAds: (typeAds, pageSize, pageNumber) => dispatch(getAds(typeAds, pageSize, pageNumber)),
  updateAds: (id, dataChange) => dispatch(updateAds(id, dataChange)),
  deleteAds: (id) => dispatch(deleteAds(id)),
  setStatusAds: (id, status) => dispatch(setStatusAds(id, status)),
  //// user
  deleteUser: (id) => dispatch(deleteUser(id)),
  filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
  createUser: (email, fullName, role, atOffice, bankID) => dispatch(addUser(email, fullName, role, atOffice, bankID)),
  updateUser: (id, dataChange) => dispatch(updateUser(id, dataChange)),

})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupContainer);