import React, { Component } from 'react'
import styled from "styled-components";

//container
import BlockNameControl from "./BlockNameControl";
import AddMore from "./AddMore";
import SearchControl from './SearchControl';
import SortControl from './SortControl';

const Bound = styled.div`
    display:flex;
    margin-bottom:7px;
    height:70px;
    flex-direction:row;
    justify-content:space-between;
    .block-left{
        display:flex;
        align-items:center;
    }
`

export default class SubHeaderControl extends Component {
    render() {
        const {data} = this.props
        return (
            <Bound>
                <BlockNameControl
                    mainname={data.mainTitle}
                    subname={data.subTitle}
                />
                <div className="block-left">
                    {
                        data.buttonArr.search&&
                        <SearchControl
                        searchFilter={this.props.searchFilter}
                        inputname={data.buttonArr.search.placeHolder}/>
                    }
                    {
                        data.buttonArr.filter&&
                        <SortControl 
                        toggleFilter={()=>{this.props.toggleFilter()}}                        
                        />
                    }
                    {   
                        this.props.isAdd === true?                        
                        null
                        :                                               
                        data.buttonArr.addMore&&
                        <AddMore namebtn={data.buttonArr.addMore.title} onSubmit={this.props.onAddMoreSubmit}/>                                                 
                    }
                </div>
            </Bound>
        )
    }
}
