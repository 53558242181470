import Axios from 'axios';

import * as types from './types';
import { host } from "../host";

const apiCreateOffice = host + "admin/office/create";
const apiFilteOffice = host + "admin/office/filter";
const apiUpdateOffice = host + "admin/office/update";
const apiDeleteOffice = host + "admin/office/delete";
Axios.defaults.withCredentials = true;

/**
 * 
 * @param {String} name name of office
 * @param {String} address full address of office
 * @param {String} province province of office
 * @param {String} district district of office
 * @param {string} ward ward of office
 * @param {"SAVING" \| "TRADING" \| "BRANCH"} officeType type of office
 */
export const createOffice =  (name, address, province, district, ward, officeType) => async dispatch => {
  // console.log(name, address, province, district, ward, officeType);
  const queryFormatData = JSON.stringify({
    Name: name,
    Address: address,
    Province: province,
    District: district,
    Ward: ward,
    OfficeType: officeType
  })
  console.log(queryFormatData)
  try {
    const res = await Axios.post(apiCreateOffice, queryFormatData);

    // console.log(res)
    const {data} = res;
    dispatch({
      type: types.CREATE_NEW_OFFICE,
      success: Boolean(data)
    })
  }
  catch(err){
    // console.log(err)
    dispatch({
      type: types.CREATE_NEW_OFFICE_ERR,
      err
    })
  }
}

/**
 * 
 * @param {{
 *  name: string, 
 *  address: string, 
 *  province: string, 
 *  district: string, 
 *  ward: string,
 *  officeType: "SAVING" \| "TRADING" \| "BRANCH"
 * }} dataFilter 
 * @param {number} pageSize 
 * @param {number} pageNumber 
 */
export const filterOffice = (dataFilter, pageSize, pageNumber) => async dispatch => {

  const formatDataFilter = {};
  if(dataFilter.name) formatDataFilter["Name"] = dataFilter.name;
  if(dataFilter.adress) formatDataFilter["Address"] = dataFilter.adress;
  if(dataFilter.province) formatDataFilter["Province"] = dataFilter.province;
  if(dataFilter.district) formatDataFilter["District"] = dataFilter.district;
  if(dataFilter.ward) formatDataFilter["Ward"] = dataFilter.ward;
  if(dataFilter.officeType) formatDataFilter["OfficeType"] = dataFilter.officeType;
  

  const queryFormatData = JSON.stringify({
    ...formatDataFilter,
    PageSize: pageSize,
    PageNumber: pageNumber
  })
  // console.log(queryFormatData)

  try {
    const res = await Axios.post(apiFilteOffice, queryFormatData);

    // console.log('test RESULT: ',res)
    const { data } = res;
    dispatch({
      type: types.FILTER_OFFICE,
      listOffice: data.ListOffice,
      total: data.Total,
      dataFilter
    })
  }
  catch (err) {
    // console.log(err)
    dispatch({
      type: types.FILTER_OFFICE_ERR,
      err
    })
  }
}

export const getAllOffice = () => async dispatch => {
  try {
    // const res = await Axios.post(
    //   apiFilteOffice, 
    //   JSON.stringify({
    //     PageSize: 10,
    //     PageNumber: 1
    //   })
    // );
    const all = await Axios.post(
      apiFilteOffice,
      JSON.stringify({
        PageSize: 100000,
        PageNumber: 1
      })
    );
    // console.log(all)
    dispatch({
      type: types.FILTER_OFFICE,
      listOffice: all.data.ListOffice,
      total: all.data.Total,
      dataFilter: {}
    })
  }
  catch (err) {
    // console.log(err)
    dispatch({
      type: types.FILTER_OFFICE_ERR,
      err
    })
  }
}

/**
 * 
 * @param {{
 * ID*: string,
 * Name:  string,
 * Address: string,
 * Province: string,
 * District: string,
 * Ward: string,
 * OfficeType: "SAVING" \| "TRADING" \| "BRANCH"
 * }} dataUpdate 
 */
export const updateOffice = (dataUpdate) => async dispatch => {
  const queryFormatData = JSON.stringify({
    ...dataUpdate
  })

  try {
    const res = Axios.post(apiUpdateOffice, queryFormatData);
    console.log(res);
    const {data} = res;
     dispatch({
      type: types.UPDATE_OFFICE,
      success: data.Code === 0
    })

  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.UPDATE_OFFICE_ERR,
      err
    })
    if(err.response){
      console.log(err.response.status)
    }
  }
}

/**
 * 
 * @param {string} idOffice 
 */
export const deleteOffice = (idOffice) => async dispatch => {
  const dataQueryFormat = JSON.stringify({
    ID: idOffice
  });

  try {
    const res = Axios.post(apiDeleteOffice, dataQueryFormat);
    console.log(res);
    const {data} = res;

    dispatch({
      type: types.DELETE_OFFICE,
      success: data.Code === 0
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.DELETE_OFFICE_ERR,
      err
    })
    if(err.response){
      console.log(err.response.status)
    }
  }
}

export const resetStateOffice = () => (
  {
  type:types.RESET_STATE_OFFICE
}
)

export const alertFillterOffice = () => {
  return {
    type: types.ALERT_CONTENT
  }
}

