import React, { Component } from 'react'
import styled from 'styled-components';
// Component
import SubHeaderControl from '../Control/SubHeaderControl'
import PagingControl from '../Control/PagningControl';
import EmptyControl from "../Control/EmptyControl";
import DeviceKiosItem from './DeviceKiosItem';
import DeviceLCDItem from './DeviceLCDItem';
// Data
import { TrandingRoomTabArr, emptyData } from '../../../../data/data'

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    min-width:1260px;
    .title {
        background-color:#fafafa;
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container{
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
        .title-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: #005993;
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow:hidden;
            p{
                text-transform: uppercase;
            }
            .scroll{
                overflow:auto;   
                .item-child {
                    display: grid;
                    grid-template-columns: 280px 280px 280px 280px;
                    justify-content: space-evenly;
                    grid-gap: 20px;       
                         
                    ::-webkit-scrollbar { 
                    display: none; 
                    }
                }    
            }
        }
        
    }
`
const limitPerPage = 8
export default class TrandingRoomDevice extends Component {
	_isMounted = false;
	state = {
		typeSelected: TrandingRoomTabArr[0].key,
		subHeaderData: TrandingRoomTabArr[0].subHeaderData,
		data: this.props.data,
		currentPage: 1,
		startIndex: 0,
		endIndex: 10,
		totalAds: 8,
		alertContent:'',
	}
	componentDidMount() {
		this._isMounted = true;
		const { typeSelected } = this.state
		this._isMounted && this.pagnationPage();
		// this._isMounted && this.props.getAds(typeSelected, 10, 1)
	}

	setTypeSelected = (tab) => {
		if (tab) {
			const { typeSelected } = this.state
			if (typeSelected !== tab.key) {
				// this.props.getAds(tab.key, 10, 1)
			}
			this.setState({
				typeSelected: tab.key,
				subHeaderData: tab.subHeaderData
			})
		}
	}
	pagnationPage() {
		let endIndex = this.state.currentPage * limitPerPage;
		let startIndex = endIndex - limitPerPage;
		this.setState({ startIndex, endIndex })
	}
	onPageChange = (e) => {
		let currentPage = e
		let endIndex = currentPage * limitPerPage;
		let startIndex = endIndex - limitPerPage;
		this.setState({ currentPage, startIndex, endIndex });
	}
	showPopupAddAdvert = () => {
		const { typeSelected } = this.state
		switch (typeSelected) {
			case TrandingRoomTabArr[0].key: {
				this.props.showAddTrandingRoomKioskPopup()
				break;
			}
			case TrandingRoomTabArr[1].key: {
				this.props.showAddTrandingRoomLCDPopup()
				break;
			}
			default:
				break;
		}
	}
	renderTab() {
		return TrandingRoomTabArr.map((tab, i) => {
			return (
				<div className='choise' key={i}
					style={this.state.typeSelected === tab.key ?
						{ color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
						:
						{ color: tab.disActiveColor }}
					onClick={() => this.setTypeSelected(tab)}>
					{tab.label}
				</div>
			)
		})
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	render() {
		// const { listAds, totalAds } = this.props.getAdsReducer;               
		let { subHeaderData, startIndex, endIndex, typeSelected } = this.state
		let dataSlice = this.state.data.slice(startIndex, endIndex)
		// console.log(dataSlice);
		
		return (
			<Bound>
				<div className='title'>
					{this.renderTab()}
				</div>
				<div className='ad-container'>
					<SubHeaderControl
						data={subHeaderData}
						onAddMoreSubmit={this.showPopupAddAdvert} />

					{this._isMounted && this.state.data.length > 0
						? (
							<div className="item">
								<div className='title-wrapper'>
									<p>danh sách quảng cáo</p>
									{
										(this.state.totalAds > limitPerPage) ?
											<PagingControl
												currentPage={this.state.currentPage}
												totalItem={this.state.totalAds}
												limitPerPage={limitPerPage}
												onChangePage={page => this.onPageChange(page)} /> : null
									}
								</div>
								<div className='scroll'>
									<div className="item-child">
										{
											this._isMounted && dataSlice.map((item, i) => {
												switch (typeSelected) {
													case TrandingRoomTabArr[0].key: {
														return (
															<DeviceKiosItem
																key={i}
																keyValue={i}
																data={item}
																onShowControl={(offset, from, data) => {
																	this.props.onShowControl(offset, from, data);
																}} />
														)
													}
													case TrandingRoomTabArr[1].key: {
														return (
															<DeviceLCDItem
																key={i}
																keyValue={i}
																data={item}
																onShowControl={(offset, from, data) => {
																	this.props.onShowControl(offset, from, data);
																}} />
														)
													}
													default:
														break;
												}
												return null;
											})
										}
									</div>
								</div>
							</div>
						)
						: (<EmptyControl
							alertContent={this.state.alertContent}
							widthInput={400}
							content={emptyData.deviceKiosk} />)
					}
				</div>

			</Bound>
		)
	}
}
