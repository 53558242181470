import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.button`
    width: 100px;
    height: 36px;
    border-radius: 18px;
    background-color: ${props =>props.bg_color || '#005993'};
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    border:none;
    cursor: pointer;
    .titleConfirm{
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
    }
`

export default class ButtonConfirmPopup extends Component {
    render() {
        // let {dataNeedModify} = this.props
        return (
            <Bound 
                bg_color={this.props.bg_color} 
                onClick={()=>{                
                return this.props.onClick()
            }}>
                <span className='titleConfirm'>
                    {this.props.titleConfirm}
                </span>
            </Bound>
        )
    }
}
