import React, { Component } from 'react'
import styled from 'styled-components'
import ic_close from '../../../images/ic_close_white.svg'
import InputTextControl from '../MainScreen/Control/InputTextControl';
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';

const Bound = styled.div`
    padding: 13px 13px 20px 13px;
    width: 454px;
    height: auto;
    background-color:#fafafa;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .row_header{
        display: flex;
        justify-content:space-between;
        align-items: center;
        .titleHeader{
            font-size: 20px;
            font-weight: bold;
            line-height: 1.1;
            color: #000000;
        }
        .ic_close {
            cursor: pointer;
        }
    }
    .row_main{
        padding: 0 7px;
        margin: 20px 0 0 0;
        h4 {
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform:uppercase;
            margin: 16px 0 0 0;
        }
        .NameCounter,.AllCounter{
            height: 44px;
        }
    }
    .row_flex{
        display: flex;
        align-items: center;
        .numberCounter{
            width:200px;
        }
        .checkBox_priority{
            margin: 0 0 0 40px;
            .radio-field {
              display: flex;
              align-items: center;
              position: relative;
              input[type="checkbox"] {
                position: absolute;
                height: 0;
                width: 0;
              }
        
        .checkmark {
          position: absolute;
          top: 1.5px;
          left: 4.5px;
          height: 16px;
          width: 16px;
          border: solid 0.8px #c8c8c8;
          background: #ffffff;
        }
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        input[type="checkbox"]:checked ~ .checkmark:after {
          display: block;
        }
        .checkmark::after {
          left: 4.5px;
          top: 1.5px;
          width: 4px;
          height: 8px;
          border: solid #d71249;
          border-width: 0 3px 3px 0;    
          transform: rotate(45deg);
        }
        div {
          margin-left: 32px;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          color: #000000;
        }
      }
        }
    }
    .row_btn{
      margin: 40px 0 0 0;
      display:flex;
      justify-content:flex-end;
      .btn_success{
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
`

export default class PopupAddCounter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      idShow: "",
      priorityCounter: false,
      nameCounter:'',
      numberCounter:0,
      servAllService: false,
      serviceList: []
    }
    
  }
  setIdShow = (id) => {
    this.setState({ idShow: id })
  }

  /// get dropdown
  getText = (type) => text => {
    this.setState({ [type]: text },()=>{console.log(this.state.numberCounter);
    })
  }

  getTextChooseService = (text) => {
    if (text === 'Tất cả dịch vụ'){
      this.setState({ servAllService: true})
    }
  }

  getNameCounter = (e) => {
    this.setState({
      nameCounter:e
    });
  }

  toggleCheckboxChange = () => {
    this.setState({
      priorityCounter:!this.state.priorityCounter
    },()=>{console.log(this.state.priorityCounter);
    });
  }

  submit = () => {
    console.log(this.state)
    // this.props.addCounter(this.state.priorityCounter)
  }

  render() {
    let numberCounter = this.getText('numberCounter')
    let getTextChooseService = this.getText('servAllService')
    return (
      <Bound>
        <div className="row_header">
          <div className="titleHeader">
            {this.props.titleHeader}
          </div>
          <div>
            <img
              onClick={() => this.props.outPopup()}
              className='ic_close' src={ic_close} alt="abc" />
          </div>
        </div>
        <div className="row_main">
          <h4>tên quầy giao dịch </h4>
          <div className="NameCounter">
            <InputTextControl
              name='nameCounter'
              getText={value => this.getNameCounter(value)}
              placeHolder='Nhập tên quầy giao dịch'
            //  defaultValue={dataNeedModify ? dataNeedModify.Name : ''}
            />
          </div>
          <h4>dịch vụ áp dụng</h4>
          <div className="AllCounter">
            <SingleChoiseDropDownControl
              id="selectAllCounter"
              idShow={this.state.idShow}
              setIdShow={this.setIdShow}
              titleChoise='Tất cả dịch vụ'
              getText={getTextChooseService}
            // data={this.state.data.office}
              defaultValue='Tất cả dịch vụ'
            />
          </div>
          <h4>Số quầy </h4>
          <div className="row_flex">
            <div className="numberCounter">
              <SingleChoiseDropDownControl
                id="selectNumberCounter"
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}
                titleChoise='Chọn số quầy'
                getText={numberCounter}
              // data={this.state.data.office}
                defaultValue={1}
              />
            </div>
            <div className="checkBox_priority"

            >
              <label className="radio-field">
                <input type="checkbox" name="content" value='abc' checked={this.state.priorityCounter}
                onChange={this.toggleCheckboxChange}
                />
                <span className="checkmark"></span>
                <div>Quầy ưu tiên</div>
              </label>
            </div>
          </div>
          <div className="row_btn">
          <div 
          onClick={this.props.acceptAction}
          className='btn_success'>HOÀN TẤT</div>
          </div>
        </div>
      </Bound>
    )
  }
}
