import React, { Component } from 'react'
import styled from 'styled-components'

const Bound=styled.div`
    display:flex;
    flex-direction:column;
    padding: 5px 0;
    width:78px;
    position:absolute;
    top:${props=>props.offset.top}px;
    left:${props=>props.offset.left}px;
    background-color:white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    div{
        width:100%;
        height:22px;
        padding:0 7px;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.68;
        letter-spacing: normal;
        cursor:pointer;
    }
    .modify{
        color:#222222;
    }
    .remove{
        color:#f31919;
    }
`

export default class PopupControl extends Component {
    state =  {
        offset: this.props.offsetPopup
    }
    render() {
        // console.log(this.props.from);
        // console.log(this.props.dataReceive);
        const {from, dataReceive} = this.props;
        return (
            <Bound offset={this.state.offset} ref={ref => this.popupRef = ref}>
                <div className='modify'
                    onClick={()=>{this.props.onModify(from, dataReceive)}}>Tùy chỉnh</div>
                <div className='remove'
                    onClick={()=>{this.props.onRemove(from, dataReceive)}}>Xóa</div>
            </Bound>
        )
    }

    componentDidMount() {
        const {offset} = this.state;
        if (this.popupRef.offsetTop + this.popupRef.offsetHeight > window.innerHeight - window.screenTop){
            offset.top = this.popupRef.offsetTop - this.popupRef.offsetHeight;
            this.setState({offset})
        }
    }
    
}
