import Axios from 'axios';

import * as types from './types';
import {host} from "../host";
// import { getInfoImageOrVideo } from '../tools';

// api
const apiGetAds = host + "admin/ads/get-and-filter";
const apiCreateAds = host + "admin/ads/create";
const apiUpdateAds = host + "admin/ads/update";
const apiDeleteAds = host + "admin/ads/delete";
const apiSetStatusAds = host + "admin/ads/active";
const apiGetFile = host + "admin/ads/file";
Axios.defaults.withCredentials = true;

/**
 * @param {"GDV" \| "KIOSK" \| "LCD"} typeAds
 * @param {number} pageSize
 * @param {number} pageNumber
 * @param {string=undefined} SearchText
 */
export const getAds = (typeAds, pageSize, pageNumber, SearchText=undefined) => async dispatch => {
  // console.log(typeAds)
  const dataFormat = {
    TypeAds: typeAds,
    PageSize: pageSize,
    PageNumber: pageNumber
  }

  if (SearchText) dataFormat["SearchText"] = SearchText

  const querydataFormat = JSON.stringify({
    ...dataFormat
  })
  
  try {
    // console.log(apiGetAds)
    // console.log(querydataFormat)
    const res = await Axios.post(apiGetAds, querydataFormat);
    // console.log(res);
    const data = res.data;

    const mapImageToListAds = data.ListAds.map( async ads => {
      // console.log(ads.FileID)
      // TODO: UNCOMMENT code get file to get file size and file type
      // const getImg = await Axios.get(apiGetFile + "?id=" + ads.FileID, {
      //   // params: {
      //   //   id: ads.FileID
      //   // },
      //   // responseType: 'blob'
      // })
      // console.log(getImg)
      
      // return {
      //   ...ads,
      //   fileSrc:  getImg.request.responseURL,
      //   // fileSize: getImg.data.size,
      //   // fileType: getImg.data.type.split("/")[1]
      // }   
      return {
        ...ads,
        fileSrc: apiGetFile + "?id=" + ads.FileID,
        fileSize: 15000,
        fileType: "png"
      }   
      // return {
      //   ...ads
      // }     
    })

    Promise.all(mapImageToListAds)
    .then(dta => dispatch({
      type: types.GET_ADS,
      listAds: dta,
      totalAds: data.TotalAds
    }));
   
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.GET_ADS_ERR,
      err
    })
  }
}

/**
 * @param {string} title
 * @param {string} desc
 * @param {string($binary)} upload
 * @param {"KIOSK" \| "GDV" \| "lCD"} typeAds
 * @param {boolean} active
 * @param {array=undefined} applyOffice
 */
export const createAds = (title, desc, upload, typeAds, active, applyOffice = undefined) => async dispatch => {

  const bodyFormData = new FormData();

  bodyFormData.set("title", title);
  bodyFormData.set("desc", desc);
  bodyFormData.append("upload", upload);
  bodyFormData.set("type_ads", typeAds);
  bodyFormData.set("active", active);
  applyOffice && applyOffice.map(item => bodyFormData.append("apply_at_office", item))
  // for (var pair of bodyFormData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }

  try{
    const res = await Axios.post(apiCreateAds, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    console.log(res);
    const {data} = res;
    dispatch({
      type: types.CREATE_NEW_ADS,
      success: Boolean(data.ID)
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.CREATE_NEW_ADS_ERR,
      err
    })
  }
}

/**
 * @param {string} id
 * @param {{
 * title: string, 
 * type: "KIOSK" \| "GDV" \| "LCD", 
 * desc: string, 
 * upload: string($binary), 
 * applyAtOffice: array,
 * status: boolean
 * }} dataChange
 */
export const updateAds = (id, dataChange) => async dispatch => {
  console.log(id,dataChange)
  const updateFormData = new FormData();

  updateFormData.set("id", id);
  dataChange.title && updateFormData.set("title", dataChange.title);
  dataChange.type && updateFormData.set("type", dataChange.type);
  dataChange.desc && updateFormData.set("desc", dataChange.desc);
  dataChange.upload && updateFormData.append("upload", dataChange.upload);
  dataChange.applyAtOffice && dataChange.applyAtOffice.map(item => updateFormData.append("apply_at_office", item))
  // dataChange.applyAtOffice && updateFormData.append("apply_at_office", dataChange.applyAtOffice);
  // for (var pair of updateFormData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }
  try {
    const querydataFormat = JSON.stringify({
      ID: id,
      Active: dataChange.status
    })
    const [updateData, updateStatus] = await Promise.all([
      Axios.post(apiUpdateAds, updateFormData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
      Axios.post(apiSetStatusAds, querydataFormat)
    ])
    // console.log(A)
    // const res = await Axios.post(apiUpdateAds, updateFormData, {
    //   headers: { 'Content-Type': 'multipart/form-data' }
    // });
    // console.log(res);
    // // update status
    // const querydataFormat = JSON.stringify({
    //   ID: id,
    //   Active: dataChange.status
    // })
    // const setSta = await Axios.post(apiSetStatusAds, querydataFormat);
    // console.log(setSta)
    // const { data } = setSta;
    // const { dataRes } = res;
    dispatch({
      type: types.UPDATE_ADS,
      success: Boolean(updateData.data) && Boolean(updateStatus.data)
    })
  }
  catch (err) {
    // console.log(err)
    dispatch({
      type: types.UPDATE_ADS_ERR,
      err
    })
  }
}

/**
 * @param {string} id
 */
export const deleteAds = (id) => async dispatch => {

  const querydataFormat = JSON.stringify({
    ID: id
  })

  try {
    await Axios.post(apiDeleteAds, querydataFormat);

    // console.log(res);
    // const {data} = res;
    dispatch({
      type: types.DELETE_ADS,
      success: true
    })
  }
  catch (err) {
    console.log(err);
    dispatch({
      type: types.DELETE_ADS_ERR,
      err
    })
  }
}

/**
 * @param {string} id
 * @param {boolean} status
 */
export const setStatusAds = (id, status) => async dispatch => {
  const querydataFormat = JSON.stringify({
    ID: id,
    Active: status
  })

  try {
    const res = await Axios.post(apiSetStatusAds, querydataFormat);

    // console.log(res);
    const { data } = res;
    dispatch({
      type: types.SET_STATUS_ADS,
      success: Boolean(data)
    })
  }
  catch (err) {
    // console.log(err);
    dispatch({
      type: types.SET_STATUS_ADS_ERR,
      err
    })
  }
}

/**
 * @param {string} idFile: id of file need get
 */
export const getFileAds = (idFile) => async dispatch => {
  try {
    const getImg = await Axios.get(apiGetFile, {
      params: {
        id: idFile
      },
      responseType: 'blob'
    })


    return {
      fileSrc:  getImg.request.responseURL,
      fileSize: getImg.data.size,
      fileType: getImg.data.type.split("/")[1]
    }   
  }
  catch (err) {
    console.log(err);
    dispatch({
      type: types.GET_FILE_ADS_ERR,
      err
    })
  }
}


export const alertAds = () => {
  return {
    type: types.ALERT_CONTENT
  }
}
export const resetAdsReducer = () => dispatch => {
  console.log("reload")
  dispatch({
    type: types.RESET_ADS_REDUCER
  })
}
