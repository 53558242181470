import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginUser, filterUser, addUser, updateUser } from './actions/userAction';
import { createCounter, getListTypeCounterServices, getCounterByOffice } from './actions/counterAction';

/**
 * TODO: this is test component, it's used to test action redux and will be deleted before deploy web 
 */
class Test extends Component {
  render() {
    return (
      <div>
        
      </div>
    )
  }
  componentDidMount() {
    console.log("Test component was add")
    // this.props.addUser()
    // this.props.loginUser()
    // this.props.createCounter()
    // this.props.filterUser()
    // this.props.updateUserRole()
    // this.props.updateUserAtOffice()
    // this.props.getListTypeCounterServices("5d09be663835db123bb57562")
    // this.props.getCounterByOffice("5d09be9e3835db123bb57563")
    // this.props.createCounter({
    //   OfficeID: "5d01fbbb513dd56c97276152",
    //   CounterNum: 3,
    //   ServAllService: true,
    //   IsVIP: true,
    //   CounterName: "counter 12"
    // })
  }
  
}

const mapDispatchFromProps = (dispatch) => ({
  createCounter: (dataCreate) => dispatch(createCounter(dataCreate)),
  getCounterByOffice: (id) => dispatch(getCounterByOffice(id)),
  getListTypeCounterServices: (userId) => 
    dispatch(getListTypeCounterServices(userId)),
  loginUser: () => 
    dispatch(loginUser(
      "Adcfg@gmail.com", 
      "asd"
    )),
  filterUser: () => 
    dispatch(filterUser(
      {}, 10, 1
    )),
  addUser: () => 
    dispatch(addUser({ 
      Email: "vtcong@gmail.com", 
      FullName: "Vu Thanh Cong", 
      Role: "ADMIN", 
      AtOffice: "5d01fbbb513dd56c97276152", 
      BankID: "BANKID" 
    })),
  createCounter: () => 
    dispatch(createCounter({
      OfficeID: "5d0772639baf20c86d98291e",
      CounterNum: 5,
      ServAllService: true,
      CounterName: "string"
    })),
  updateUserRole: () => 
    dispatch(updateUser(
      {
        Role: "OFFADMIN"
      }, 
      "5d08977a56d3a0df510d8e5a"
    )),
  updateUserAtOffice: () => 
    dispatch(updateUser(
      {
        AtOfficeID: "5d08c3d8be046ed2d9fef72d"
      }, 
      "5d08977a56d3a0df510d8e5a"
    )),
})

export default connect(null, mapDispatchFromProps)(Test)
