import Axios from 'axios';

import * as types from './types';
import { host } from "../host";

const apiAddUser = host + "admin/user/add";
const apiUpdateUser = host + "admin/user/update";
const apiDeleteUser = host + "admin/user/delete";
const apiFilterUser = host + "admin/user/filter";
const apiLogin = host + "admin/user/login";
const apiLogout = host + "admin/user/logout";
// const apiGetOfficeById = host + "admin/office/get";
Axios.defaults.withCredentials = true;

/**
 * @param {{
 * Email*: string,
 * FullName*: string,
 * Role*: "ADMIN" \| "TELLER" \| "OFFADMIN",
 * AtOffice*: string - ID of office,
 * BankID*: string
 * }} infoUser
 */
export const addUser = (infoUser) => async dispatch => {
  const queryFormat = JSON.stringify({
    ...infoUser
  })
  
  try {
    const res = await Axios.post(apiAddUser, queryFormat);
    // const atOffice = await 
    const {data} = res.data;
    // console.log(res)
    dispatch({
      type: types.ADD_USER,
      success: Boolean(data && data.ID)
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.ADD_USER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {{
 * Role: string, - new role of user
 * AtOfficeID: string - new id of office apply
 * }} dataUpdate 
 * @param {string*} idUser id of user will update
 */
export const updateUser = (dataUpdate, idUser) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    UID: idUser,
    ...dataUpdate
  })
  console.log(queryDataFormat)
  try {
    const res = await Axios.post(apiUpdateUser, queryDataFormat);

    console.log(res);
    dispatch({
      type: types.UPDATE_USER
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.UPDATE_USER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} id: id of user need delete 
 */
export const deleteUser = (id) => async dispatch => {
  const queryFormat = JSON.stringify({
    ID: id
  })

  try {
    await Axios.post(apiDeleteUser, queryFormat);
  

    dispatch({
      type: types.DELETE_USER,
      success: true
    })
  }
  catch(err){
    dispatch({
      type: types.DELETE_USER_ERR,
      err
    })
  }
} 

/**
 * 
 * @param {{
 * Search: string - email or username
 * AtOffice: string - id of office
 * Role: "ADMIN" \| "TELLER" \| "OFFADMIN"
 * }} dataFilter 
 * @param {number*} pageSize
 * @param {number*} pageNumber 
 */

export const filterUser = (dataFilter, pageSize, pageNumber) => async dispatch => {
  Axios.defaults.withCredentials = true;
  const queryFormat = JSON.stringify({
    ...dataFilter,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  try {
    const res = await Axios.post(apiFilterUser, queryFormat);
    // console.log(res)
    const {data} = res;
    // const mapOfficeName = data.ListUser&&data.ListUser.map(async user => {

    //   // // TODO: re-insert data to use api
    //   // const office = await Axios.post(apiGetOfficeById, JSON.stringify({
    //   //   // ID: data.AtOffice
    //   //   ID: "5d076ba79baf20c86d982919"
    //   // }))
    //   // console.log(office)
    //   return {
    //     ...user,
    //     // AtOfficeName: office.data.Office.Name
    //   }
    // })

    dispatch({
      type: types.FILTER_USER,
      listUser: data.ListUser ? data.ListUser: [],
      total: data.Total
    })

  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.FILTER_USER_ERR,
      err
    })
  }
}


// TODO: reset email
// TODO: reset password
/**
 * 
 * @param {string} email email of user account
 * @param {string} pwd password of user account
 */
export const loginUser = (email, pwd) => async dispatch => {
  // console.log("login")
  const queryData = JSON.stringify({
    Email: email,
    Pwd: pwd
  })
  // console.log(apiLogin, queryData)
  try {
    Axios.defaults.withCredentials = true;
    const res = await Axios.post(apiLogin, queryData);
    console.log(res);
    const {data} = res;
    window.localStorage.setItem("dataLogin", JSON.stringify(data))
    dispatch({
      type: types.LOGIN_USER,
      data
    })
  }
  catch(err) {
    // console.log(err)
    dispatch({
      type: types.LOGIN_USER_ERR,
      err
    })
    if (err.response) {
      console.log(err.response.status)
    }
  }
  
}

/**
 * 
 * @param {string} userId id of user signed in
 */
export const logoutUser = (userId) => async dispatch => {
  const queryData = JSON.stringify({
    UID: userId
  })

  try {
    const res = Axios.post(apiLogout, queryData);
    console.log(res)
    const {data} = res;
    localStorage.removeItem("dataLogin")
    dispatch({
      type: types.LOGOUT_USER
    })
    
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.LOGOUT_USER_ERR,
      err
    })
  }
}

 


