import React, { Component } from "react";
import styled from "styled-components";
//Data
import { color } from "../../../data/store";
import { mainMenu, webVersion, settingObj,  } from "../../../data/data";
import * as userRole from '../../../data/userRole';
//Component
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";

const Bound = styled.div`
  /* position:absolute; */
  display: flex;
  flex-direction: column;
  width: ${props=>props.isShow?180:48}px;
  height:calc(100vh - 60px);
  background-color: ${color.blue93};
  overflow:hidden;
  transition:width 0.5s;
  /* z-index:10; */
  .toggle-menu{
    display:flex;
    width:100%;
    height:40px;
  }
  .menu-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    width:180px;
    ul {
      width: 100%;
      padding-top: ${props=>props.isActive?32:72}px;
      li:nth-child(5) {
        position: absolute;
        bottom: 69px;
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #fafafa;
        padding-left: 16px;
        font-weight: normal;
        font-style: normal;
      }
      li:nth-child(6) {
        position: absolute;
        bottom: 0;
        background-color: ${color.blue93};
        height: 60px;
        line-height: 60px;
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          text-decoration: none;
          font-family: SVN-Gilroy;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.13;
          color: #fafafa;
          img {
            padding: 0 12px;
          }
        }
      }
    }
    .menu-footer {
      p {
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #fafafa;
        font-weight: normal;
        font-style: normal;
        margin: 0 0 30px 16px;
      }
      a {
        line-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 25px;
        text-decoration: none;
        font-family: SVN-Gilroy;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.13;
        color: #fafafa;
        margin-bottom: 18px;
        img {
          padding: 0 12px;
        }
      }
    }
  }
  
`

export default class MenuContainer extends Component {
  constructor(props) {
    super(props);
    this.Active=false;
    this.state = {
      isShow:true,
      pathActive: window.location.pathname
    }

    // this.permission = JSON.parse(localStorage.getItem("checkLogin")).permission;
  }

  toggleMenu=()=>{
    let {isShow} =this.state
    this.setState({isShow:!isShow})
  }

  onActiveMenu(pathActive) {
    this.setState({
      pathActive
    });
  }

  checkMatchPermission = (keyCheck, propertyCheck) => {
    // return object contain key
    const checkIncludeKey = key => property => object =>
      object[property].includes(key);

    const getKey = checkIncludeKey(keyCheck);

    const getProperty = getKey(propertyCheck);

    const getMenuItem = mainMenu.find(getProperty);

    // return getMenuItem.permissions.includes(this.permission);
    return getMenuItem.permissions.includes(this.props.dataLogin.Role);
  };

  renderMenu() {
    // const accountInfo = JSON.parse(window.localStorage.getItem("checkLogin"));
    const getMenu = permission => object =>
      object.permissions.includes(permission);

    // const getMenuAdminRoot = getMenu("adminRoot");
    const getMenuAdminRoot = getMenu(userRole.ADMIN_ROLE);
    // const getMenuAdminBranch = getMenu("adminBranch");
    const getMenuAdminBranch = getMenu(userRole.ADMIN_OFFICE_ROLE);
    // console.log( 'getMenuAdminRoot',getMenuAdminRoot )

    let menu;

    // if (accountInfo && accountInfo.permission === "adminRoot") {
    if (this.props.dataLogin && this.props.dataLogin.Role === userRole.ADMIN_ROLE) {
      menu = mainMenu.filter(getMenuAdminRoot);
      // console.log('menu',menu);

    // } else if (accountInfo && accountInfo.permission === "adminBranch") {
    } else if (this.props.dataLogin && this.props.dataLogin.Role === userRole.ADMIN_OFFICE_ROLE) {
      menu = mainMenu.filter(getMenuAdminBranch);
    }
    return menu.map((item, i) => {
      // console.log(this.state.pathActive);
      return (
        <MenuItem
          key={i}
          data={item}
          pathActive={this.state.pathActive}
          isShow={this.state.isShow}
          onActiveMenu={this.state.isShow?this.onActiveMenu.bind(this):()=>{}}
        />
      );
    });
  }
  render() {
    return (
      <Bound isShow={this.state.isShow} isActive={this.Active}
        onMouseEnter={()=>{this.isMouseEnter=true}}
        onMouseLeave={()=>{
          this.isMouseEnter=false
          if(this.Active){
            setTimeout(() => {
              if(!this.isMouseEnter){
                this.setState({isShow:false})
              }
            }, 300);
          }
        }}>
          {
            this.Active&&
            <div className='toggle-menu' onClick={this.toggleMenu}></div>
          }
        
        <div className='menu-container'>
          <ul>{this.renderMenu()}</ul>
          <div className="menu-footer">
            {
              this.state.isShow&&
              <p>Phiên bản v{webVersion}</p>
            }
            <Link to={settingObj.key}>
              <img src={settingObj.icon} alt={settingObj.content} />
              {settingObj.content}
            </Link>
          </div>
        </div>
      </Bound>
    );
  }
  componentWillMount(){
    if(window.innerWidth>=1024){
      this.Active=false
    }
    else{
      this.Active=true
    }
  }
  componentDidMount() {
    if (!this.checkMatchPermission(window.location.pathname, "key") || window.location.pathname === "/") {
      this.setState({
        pathActive: this.props.dataLogin.Role === userRole.ADMIN_ROLE ? '/danh-sach-giao-dich' : '/thiet-bi-kiosk'
      })
    }
    if(this.Active){
      this.setState({isShow:false})
    }
  }
}
