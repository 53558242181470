import React, { Component } from "react";
import styled from "styled-components";

// import image-icon
import icMoreGray from "../../../../images/ic_more_gray.svg";
import icLCDGreen from "../../../../images/ic_LCD_green.svg";
import icLCDGrey from "../../../../images/ic_LCD_grey.svg";
import icRefresh from "../../../../images/ic_refresh.svg";

const StyledDeviceKiosItem = styled.div`
    width: 280px;
    height: 140px; 
    background: #ffffff;  
    font-family: "SVN-Gilroy";
    &:last-child{
        margin-bottom:40px;
    }
    .LCD-item__title {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-left: 12px;
        img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        }
        div {
        flex-grow: 1;
        font-size: 20px;
        font-weight: bold;
        color: #222222;
        margin-left: 4px;     
        }
    }
    .LCD-active__content{
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;     
        margin-left: 20px;
        margin-top: 15px;
        .LCD-item__status{
            color: #979797;
        }
        .LCD-item__id-number {        
            color: #222222;  

        }
    }
    .LCD-item__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        a,
        div {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.68;
        }
        a {
        color: #0062d7;
        margin: 0 14px 12px 0;
        }
    }
`;
export default class DeviceKiosItem extends Component {
    _isMounted = false;
  state = {
    data: this.props.data,
    isHover: false,
  }
  onShowControl(index, data) {    
    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound= bound.top + scrollTop
      let leftBound= bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      }
      console.log(index,data);
      this.props.onShowControl(offsetPopup, 'DeviceLCDPGD', data)
    }
  }
  render() {
    const { status, deviceName, deviceStatus } = this.props.data;
    const { keyValue } = this.props;    
    return (
      <StyledDeviceKiosItem>
        {/* title */}
        <div className="LCD-item__title">
            <img
                src={status !== "online" ? icLCDGrey : icLCDGreen}
                alt="status"
            />
            <div>{this.props.data.kioskLCD}</div>
            <img src={icMoreGray} alt="more" id={'more_button_id_' + this.props.keyValue}
            onClick={() => this.onShowControl(this.props.keyValue,this.props.data)}/>
        </div>
        {/* content */}
        <div className='LCD-active__content'>            
            {status !== "online" ? 
            <div className='LCD-item__status'>{deviceStatus}</div> 
            : 
            <div className="LCD-item__id-number">{deviceName}</div>
            }
        </div>
        {/* footer */}
        <div className="LCD-item__footer">
            {status !== "online" ? (
            <a href="/">Connect ID</a>
            ) 
            :             
            null
            }
        </div>
      </StyledDeviceKiosItem>
    );
  }
}
