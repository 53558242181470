import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Control
import SingleChoiseDropDownControl from '../../Main/MainScreen/Control/SingleChoiseDropDownControl'

// Images
import ic_close from '../../../images/ic_close_white.svg';
import ic_plus from '../../../images/ic_add_blue.png';
import ic_delete from '../../../images/ic_close_red.svg';
//Data
import { users } from '../../../data/store';
import { setPermissionSingleChoise } from '../../../data/singleChoiseData';
import { permission } from '../../../data/data';

//Office
import { getAllOffice } from '../../../actions/officeAction';

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    background-color: #fafafa;
    width: 760px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .popupHeader{
        display:flex;
        flex-direction:row;
        height:80px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        align-items: center;
        justify-content: space-between;
        position:relative;
        .content{
            display: flex;
            flex-direction:column;
            height:60px;
            margin-left:12px;
            justify-content:space-around;
            h4{
                font-size: 20px;
                font-weight: bold;
                color: #000000;
            }
            p{
                padding-left: 28px;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.68;
                color: #005993;
            }
        }
        .ic_close{
            position:absolute;
            right:20px;
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupFooter{
        display:flex; 
        padding: 20px 40px;
        height: 40px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        display:flex;
        align-items: center;
        justify-content:space-between;
        .oval{
            display:flex;
        }
        .btn_next_success{
            width: 100px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            display:flex;
            align-items: center;
            justify-content:center;
            font-size: 14px;
            font-weight: bold;
    
            color: #ffffff;
            cursor: pointer;
        }
    }
    .group-input{
        margin-right: 5.8px;
        margin-left: 8px;
    }
    button{
        float: right;
        margin: 23px 5.8px 0 0;
    }
`

const AddUserContainer = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding: 20px 40px 0 40px;
    overflow:hidden;
    .blockSearch{
        display:flex;
        position:relative;
        width: 400px;
        height: 40px;
        flex-direction:row;
        .input_search{
            width: 296px;
            height: 39px;
            padding: 0 10px;
            border-radius: 4px 0 0 4px;
            border: solid 0.5px #c8c8c8;
            background-color: #ffffff;
            outline:none;
            display: flex;
            &::placeholder{
                opacity: 0.25;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.68;
                color: #222222;
            }
        }
        .btn_search{
            width: 84px;
            height: 40px;
            border-radius: 0 4px 4px 0;
            background-color: #005993;
            color:#fff;
            font-size: 14px;
            display:flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .error{
            position:absolute;
            top:53px;
            left:0;
            font-size: 14px;
            font-weight: 500;
            color: #f31919;
        }
    }
    .check-block{
        height: 50px;
        margin:25px 0 50px 0;
    }
    .user-add-container{
        display:flex;
        flex:1;
        flex-direction:column;
        overflow:hidden;
        #scroll{
            overflow:auto;
            width:680px;
            padding-right:20px;
        }
        .title_listUser{
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            margin: 7px 0;
        }
    }
`
const AddPermissionContainer = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding: 20px 40px 0 40px;
    overflow:hidden;
`

const UserBound = styled.div`
    position:relative;
    padding:0 20px;
    display:flex;
    width:640px;
    height: 50px;
    align-items: center;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.68;
    letter-spacing: normal;
    color: #222222;
    margin:${props => props.margin ? props.margin : 'unset'};
    
    img{
        position:absolute;
        right:13px;
        cursor: pointer;
    }
    .item{
        width:32%;
    }
    .last{
        width:36%;
    }
    .button-control{
        display:flex;
    }
    #scroll{
        height:200px;
        overflow:auto;
    }
    #scroll::-webkit-scrollbar-track{}
    #scroll::-webkit-scrollbar
    {
        width: 3px;
        background-color: #fff;
    }
    #scroll::-webkit-scrollbar-thumb
    {
        background-color: #c4c4c4;
        border-radius: 4px;
    }
`

const Step = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 24px;
    background-color: #fafafa;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #c8c8c8;
    margin: 0 15px 0 0;
    cursor: pointer;
    &:active{
        background-color: #005993;
        color:#fff;
    }
`
class PopupAddPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersStore: users,
      emailTmp: '',
      error: '',
      users: {},
      listAddUser: [],
      step: 1,
      searchResult: null,
      data: setPermissionSingleChoise,
      idShow: "",
      Email: '',
      FullName: '',
      Role: '',
      AtOffice: '',
      listOffice: '',
      listRole: '',
      BankID: 'bank'
    }
  }
  
  setIdShow = (id) => {
    this.setState({ idShow: id })
  }
  
  
  handleEmail = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => { console.log(this.state.emailTmp) })
  }
  onSubmit = () => {
    if (this.state.emailTmp === '') {
      this.setState({ searchResult: null, error: 'Vui lòng nhập tài khoản' })
      return;
    }
    let searchResultLocal = this.state.listAddUser.find(item => item.email === this.state.emailTmp)
    if (searchResultLocal) {
      this.setState({ searchResult: null, error: 'Tài khoản này đã được thêm, vui lòng nhập tài khoản khác' })
      return;
    }
    let searchResult = this.state.usersStore.find(item => item.email === this.state.emailTmp);
    if (searchResult) {
      this.setState({ searchResult, error: '' })
    } else {
      this.setState({ searchResult: null, error: 'Tài khoản không đúng hoặc không tồn tại' })
    }
  }
  
  //Add Permission 
  getAddPermission = (data) => {
    let { Email, FullName, Role, AtOffice, BankID } = this.state
    let dataSelect = {}
    if(Email){
      dataSelect['Email'] = Email
      dataSelect.Email = Email
    }

    if(FullName){
      dataSelect['FullName'] = FullName
      dataSelect.FullName = FullName
    }
    
    if(Role){
      dataSelect['Role'] = Role.type
      if(Role === 'Giao dịch viên'){
        dataSelect.Role = 'TELLER'
      }else if(Role === 'Quản trị viên PGD'){
        dataSelect.Role = 'OFFADMIN'
      }
    }
    if(AtOffice) {
      dataSelect['AtOffice'] = AtOffice.id
      dataSelect.AtOffice = AtOffice.id
    }

    if(BankID){
      dataSelect['BankID'] = BankID
      dataSelect.BankID = BankID
    }
    data = {...dataSelect, FullName:this.FullName, Email: this.Email}
    this.props.addPermissionUser(data)
  }

  renderListUserAdd() {
    return (
      <div className="user-add-container">
        <p className='title_listUser'>DANH SÁCH TÀI KHOẢN</p>
        <div id='scroll'>
          {
            this.state.listAddUser.map((item, index) => {
              this.FullName = item.fullname
              this.Email = item.email
              return (
                <UserBound key={index} margin={'4px 0'}>
                  <div className='item'>{item.fullname}</div>
                  <div className='item'>{permission[item.permission].title}</div>
                  <div className='last'>{item.branch}</div>
                  <img src={ic_delete} className='ic_delete' id={'ic_delete_id_'} alt='ic_delete'
                    onClick={() => this.deleteUser(index)} />
                </UserBound>
              )
            })
          }
        </div>
      </div>
    )
  }

  addUserCheck = () => {
    let { searchResult, listAddUser } = this.state
    listAddUser.unshift(searchResult);
    searchResult = null
    this.setState({
      searchResult,
      listAddUser
    })
  }

  deleteUser = (index) => {
    let { listAddUser } = this.state
    listAddUser.splice(index, 1)
    this.setState({
      listAddUser
    })
  }

  getText = (type) => text => {
    this.setState({[type]: text})
  }

  getAtOffice = (type) => text => {
      this.setState({[type]: text})
  }

  render() {
    const Role = this.getText("Role");
    const atOffice = this.getAtOffice("AtOffice");
    
    let {listOffice} = this.props.filterOfficeReducer;
      listOffice = listOffice.map((item) => ({
          id: item.ID,
          name: item.Name
      }))
      
    let listRole = this.state.data.rank
    listRole = listRole.map(item => ({
        type: item.type,
        name: item.name
    }))

    return (
      <Bound>
        <div className="popupHeader">
          <div className='content'>
            <h4 className='title'>{this.props.titleHeader}</h4>
            <p>{this.state.step === 1 ? 'Tìm kiếm tài khoản Vietinbank' : 'Cấu hình phân quyền'}</p>
          </div>
          <span onClick={() => this.props.outPopup()} className='ic_close'></span>
        </div>
        {
          this.state.step === 1 &&
          <AddUserContainer>
            <div className="blockSearch">
              <input
                onChange={(e) => this.handleEmail(e)}
                className='input_search'
                type="email"
                name='emailTmp'
                placeholder='Nhập tài khoản Vietinbank'
                required />
              <div onClick={() => this.onSubmit()} className='btn_search'>Tìm kiếm</div>
              <p className='error'>{this.state.error}</p>
            </div>
            <div className='check-block'>
              {
                this.state.searchResult &&
                <UserBound>
                  <div className='item'>{this.state.searchResult.fullname}</div>
                  <div className='item'>{permission[this.state.searchResult.permission].title}</div>
                  <div className='last'>{this.state.searchResult.branch}</div>
                  <img src={ic_plus} className='ic_plus' id={'ic_plus_id_'} alt='ic_plus'
                    onClick={() => this.addUserCheck()} />
                </UserBound>
              }
            </div>
            {
              this.state.listAddUser && this.state.listAddUser.length > 0 &&
              this.renderListUserAdd()
            }
          </AddUserContainer>
        }
        {
          this.state.step === 2 &&
          <AddPermissionContainer>
            <div className='popup-drop-down'>
              <SingleChoiseDropDownControl
                id="selectRole"
                getText={Role}
                data={listRole}
                title='tài khoản'
                titleChoise='Chọn loại tài khoản'                            
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}/>
            </div>
            <div className='popup-drop-down'>
              <SingleChoiseDropDownControl
                id="selectAtOffice"
                // getText={this.getAtOffice}
                getText={atOffice}
                data={listOffice}
                title='điểm giao dịch'
                titleChoise='Chọn điểm giao dịch'                            
                idShow={this.state.idShow}
                setIdShow={this.setIdShow}/>
            </div>
          </AddPermissionContainer>
        }
        <div className="popupFooter">
          <div className="oval">
            <Step style={this.state.step >= 1 ? { backgroundColor: '#005993', color: '#fff' } : {}} onClick={() => { this.setState({ step: 1 }) }}>
              1
            </Step>
            <Step style={this.state.step >= 2 ? { backgroundColor: '#005993', color: '#fff' } : {}}
              onClick={() => {
                if (this.state.listAddUser && this.state.listAddUser.length > 0) {
                  this.setState({ step: 2 })
                }
              }}>
              2
            </Step>
          </div>
          <div className='btn_next_success' onClick={() => {
            if (this.state.step === 1) {
              if (this.state.listAddUser && this.state.listAddUser.length > 0) {
                this.setState({ step: 2 })
              }
            }
            else if (this.state.step === 2) {
              this.getAddPermission()
              this.props.outPopup()
            }
          }}>
            {this.state.step !== 2 ? 'TIẾP THEO' : 'HOÀN TẤT'}
          </div>
        </div>
      </Bound>
    );
  }
  componentDidMount() {
    this.props.getAllOffice()
  }
}

const mapStateToProps = (state) => {
  return {
    filterOfficeReducer: state.filterOfficeReducer
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOffice: () => dispatch(getAllOffice())

  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupAddPermission)
