import React, { Component } from 'react';
import styled from "styled-components";

//Image
import ic_star_gray from '../../../../images/ic_star_gray.svg';
import ic_star_yellow from '../../../../images/ic_star_yellow.svg';
import ic_more_gray from '../../../../images/ic_more_gray.svg';

const Bound = styled.div`
    position: relative;
    display: flex;
    font-family: SVN-Gilroy;
    margin-top: 5px;
    img{
        margin-right: 4px;
    }
    .ic_star {
        width: 24px;
        height: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 2px 4px 0 -5px;
        cursor: pointer;
    }
    h2{
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222222;
    }
    .ic_more_gray{
        width: 24px;
        height: 24px;
        background-image: url(${ic_more_gray});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        cursor: pointer;
    }
    
`
class TransactionCounterItem extends Component {
    //  state = {
    //     isCheckStar: false
    // }

    // onActiveStar() {
    //     this.setState({
    //         isCheckStar: !this.state.isCheckStar
    //     });
    // }

    onShowControl = (index) => {
        let moreBtn = document.getElementById('more_button_id_' + index);
        if (moreBtn) {
          let bound = moreBtn.getBoundingClientRect()
          let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          let topBound= bound.top + scrollTop
          let leftBound= bound.left + scrollLeft
          let offsetPopup = {
            top: topBound + bound.height / 2,
            left: leftBound - 78 + bound.width / 2
          };
          console.log(index);
          this.props.onShowControl(offsetPopup, 'CounterTrans')
        }
    }

    render() {
        return (
            <Bound className = 'counter-top'>
                {this.props.isVIP?
                  <div className = 'ic_star' 
                  // onClick = {this.onActiveStar.bind(this)}
                  style = {{ backgroundImage:`url(${ic_star_yellow})`}}></div>  
                :null}
                
                <h2>{this.props.counter}</h2>
                <div className = 'ic_more_gray'
                id={'more_button_id_'+this.props.index}
                onClick={()=>this.onShowControl(this.props.index)}
                
                ></div>

        {/* <img className='ic_more' src={ic_more_gray} alt="more" id={'more_button_id_' + this.props.keyValue}
                    onClick={() => this.onShowControl(this.props.keyValue, this.props.data)}
                /> */}
            </Bound>
        );
    }
}

export default TransactionCounterItem;