import React, { Component } from 'react';
import styled from "styled-components";
//Component
import TransactionCounter from './TransactionCounter';
import EmptyControl from '../Control/EmptyControl'
import SubHeaderControl from '../Control/SubHeaderControl'
//Data
import { dataCounter } from '../../../../data/store';
import {emptyData, subHeaderData} from '../../../../data/data'

const Bound = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:auto;
    padding:0 40px;
    /* .second-bar{
        display:flex;
        margin-bottom:15px;
        .block-left{
            display:flex;
            align-items:center;
        }
    } */
`

class TransactionPGDContainer extends Component {
    state = {
        Transactions: []
    }
    onAddTransaction() {
        const { Transactions } = this.state
        let num=Transactions.length+1
        let newData = {
            counterName: 'Quầy số ' + num,
            isConnect: false,
            isVIP:false,
            idCounter: 'Connect ID',
            counter: 2,
            service: 1,
            deviceConnect: [
                'Thiết bị quầy',
                'Thiết bị hỗ trợ GDV'
            ]
        }
        this.setState({
            Transactions: [...Transactions,newData]
        });
    }
    render() {
        return (
            <Bound>
                {/* header */}
                <SubHeaderControl 
                    onAddMoreSubmit={this.props.showPopupAddCounter}
                    data={subHeaderData.transactionRoomPGD}
                    // onAddMoreSubmit={()=>{this.onAddTransaction()}}
                    />
                {/* Transaction Counter */}
                {
                    this.state.Transactions&&this.state.Transactions.length>0?
                    <TransactionCounter 
                    onShowControl={(offset,from,dataReceive) => {
                        this.props.onShowControl(offset,from,dataReceive)
                    }}
                    data = {this.state.Transactions}/>
                    :
                    <EmptyControl content={emptyData.transaction}/>
                }
                
            </Bound>
        );
    }

    componentDidMount() {
        this.setState({Transactions: dataCounter})
    }
}

export default TransactionPGDContainer;