import React, { Component } from 'react'
import styled from 'styled-components'
// Component
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup'

const Bound = styled.div`
    
    font-family: 'SVN-Gilroy';
    font-size: 14px;       
    .wrapper{
        border: solid 1px #c8c8c8;
        background-color: #fafafa;
        margin: 25px;
        height:322px;
        overflow: auto;
        .room-type{          
            color: #005993; 
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 0px 10px 10px; 
            cursor: pointer;
            input{
                margin-right: 10px;
            }
            img{
                margin-left: 10px;
                display: flex;
                align-items: center;
            } 
        }
        .branch-wrapper{            
            .branch-room{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #000000;
                font-weight: 500;
                padding: 0 0 15px 20px;
            }
            input{
                margin-right: 10px;
            }
        }
        .icon{
            margin-left: 5px;
            margin-top: 4px;
            width: 6px;
            height: 10px;
            background-color: #005993;
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
        }
        .checked{
            margin-left: 5px;
            margin-top: 4px;
            width: 6px;
            height: 10px;
            background-color: #005993;
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            transform:rotate(90deg);
        }
        .main-check{
            display: flex;
            align-items: center;
        }
    }    
    button{
        float: right;
        margin: 0 40px 24px 0;
    }
`
export default class ApplicationLocation extends Component {
    state = {
        data:[],
        branchs: this.props.data,
        types: this.props.types,
        lDropdown:[],
    }
    componentWillMount = () => {
        this.buildData()
    }
    componentWillReceiveProps(n){
        console.log("receive")
        if(n){
            if(n.data!==this.props.data){
                this.setState({
                    branchs:n.data
                },()=>{
                    this.buildData()
                })
            }
        }
    }
    componentDidMount() {
        console.log(this.props.data)
    }
    

    componentDidUpdate(prevProps, prevState) {
        // console.log(prevState.branchs);
        // console.log(this.state.branchs);
        if (JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)){
            const a = this.state.data
                .reduce((all, item) => all.concat(item.lBranch), [])
                .filter(item => item.isCheck)
                console.log(a)
            this.props.getLstBranchChecked(
                this.state.data
                    .reduce((all, item) => all.concat(item.lBranch) , [])
                    .filter(item => item.isCheck)
            )
        }
        // console.log(this.state.branchs.filter(item => item.isCheck))
        // this.props.getLstBranchChecked(this.state.branchs.filter(item => item.isCheck))
    }
    
    

    buildData(){
        let {types} = this.state
        let branchs = JSON.parse(JSON.stringify(this.state.branchs))
        let data=[]
        console.log(branchs)
        if(branchs&&types){
            types.forEach(type => {
                let lBranch = []
                branchs.forEach(branch => {
                    // console.log(branch.OfficeType, type.type)
                    if (branch.OfficeType === type.type) {
                        branch.isCheck = /* false */this.props.ApplyAtOffice.some(office => branch.ID === office)
                        lBranch.push(branch)
                    }
                })
                data.push(
                    {
                        isCheck:!lBranch.some(item => !item.isCheck)/* false */,
                        name:type.name,
                        lBranch:lBranch
                    }
                )
            });
        }
        this.setState({data})
    }
    onDropDown = (index) => {
        let lDropdown  = JSON.parse(JSON.stringify(this.state.lDropdown));
        let resultSearch=lDropdown.indexOf(index)
        if(resultSearch>=0){
            lDropdown.splice(resultSearch,1)
        }
        else{
            lDropdown.push(index)
        }
        this.setState({lDropdown})
    }
    handleMainChange(mainIndex){
        let data = JSON.parse(JSON.stringify(this.state.data));
        let lDropdown = JSON.parse(JSON.stringify(this.state.lDropdown));
        if(mainIndex>=0){
            if(data[mainIndex]){
                let isCheck=data[mainIndex].isCheck
                data[mainIndex].isCheck=!isCheck
                if(data[mainIndex].lBranch&&data[mainIndex].lBranch.length>0){
                    data[mainIndex].lBranch.forEach(branch => {
                        branch.isCheck=!isCheck
                    });
                }
                let resultSearch=lDropdown.indexOf(mainIndex)
                if(resultSearch<0){
                    lDropdown.push(mainIndex)
                }
                this.setState({
                    data,
                    lDropdown
                })
            }
        }
    }
    handleSubChange(mainIndex, subIndex){
        let  data  = JSON.parse(JSON.stringify(this.state.data))
        if(mainIndex>=0){
            if(data[mainIndex]){
                if(data[mainIndex].lBranch&&data[mainIndex].lBranch.length>0){
                    if(data[mainIndex].lBranch[subIndex]){
                        let isCheck=data[mainIndex].lBranch[subIndex].isCheck
                        data[mainIndex].lBranch[subIndex].isCheck=!isCheck
                        let count=0;
                        data[mainIndex].lBranch.forEach(branch => {
                            if(branch.isCheck===!isCheck){
                                count++;
                            }
                        })
                        if(count===data[mainIndex].lBranch.length){
                            data[mainIndex].isCheck=!isCheck
                        }
                        else{
                            data[mainIndex].isCheck=false
                        }
                        this.setState({
                            data
                        })
                    }
                }
            }
        }
    }
    
    render() {
        const { data, lDropdown } = this.state
        return (
            <Bound>
                <div className='wrapper'>
                    {data&&data.map((item,i)=>{
                        return(
                            <div key={i} >
                                <div className='room-type' >
                                    <input
                                        name="mainCheck"
                                        type='checkbox'
                                        checked={item.isCheck}
                                        onChange={this.handleMainChange.bind(this,i)} 
                                        />
                                    <div className="main-check"
                                        onClick={() => this.onDropDown(i)}>
                                        {item.name}
                                        ({item.lBranch.length})
                                        <div className={lDropdown&&lDropdown.indexOf(i)>=0? 'checked' : "icon"}></div>
                                    </div>
                                </div>
                                {
                                    lDropdown&&lDropdown.indexOf(i)>=0&&
                                    <div className='branch-wrapper'>
                                        {item.lBranch.map((branch, j) => {
                                            return (
                                                <div key={j} className='branch-room'>
                                                    <input
                                                        type='checkbox'
                                                        name='checkAll'
                                                        checked={branch.isCheck}
                                                        onChange={this.handleSubChange.bind(this,i,j)}
                                                        >
                                                    </input>
                                                    <div>{branch.Name}</div>
                                                </div>
                                            )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                <ButtonConfirmPopup
                    titleConfirm={this.props.titleConfirm}
                    onClick={this.props.onHandleClick}
                />
            </Bound>
        )
    }
}
