import React, { Component } from "react";
import styled from "styled-components";

// import image-icon
import icMoreGray from "../../../../images/ic_more_gray.svg";
import icDeviceGreen from "../../../../images/ic_device_green.svg";
import icDeviceGray from "../../../../images/ic_device_gray.svg";
import icRefresh from "../../../../images/ic_refresh.svg";

const StyledDeviceKiosItem = styled.div`
  width: 280px;
  height: 140px;  
  background: #ffffff;  
  font-family: "SVN-Gilroy";
  &:last-child{
    margin-bottom:40px;
  }
  .kiosk-item__title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-left: 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    div {
      flex-grow: 1;
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      margin-left: 4px;
    }
  }
  .kiosk-active__content{
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;     
    margin-left: 20px;    
    margin-top: 15px;
    .kiosk-item__status{
      color: #979797;
    }
    .kiosk-item__id-number {         
      color: #222222; 
           
    }
  }
  .kiosk-item__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px ;
    a,
    div {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.68;      
    }
    a {
      color: #0062d7;
      margin: 0 14px 12px 0;      
    }    
  }
`;
export default class DeviceKiosItem extends Component {
  _isMounted = false;
  state = {
    data: this.props.data,
    isHover: false,
  }

  onShowControl(index, data) {    
    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound= bound.top + scrollTop
      let leftBound= bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      };
      console.log(index,data);
      this.props.onShowControl(offsetPopup, 'DeviceKioskPGD', data)
    }
  }
  render() {
    const { status, deviceName ,deviceStatus} = this.props.data;
    const { keyValue } = this.props;
    return (
      <StyledDeviceKiosItem>
        {/* title */}
        <div className="kiosk-item__title">
          <img
            src={status !== "online" ? icDeviceGray : icDeviceGreen}
            alt="status"/>
          <div>{this.props.data.kioskName}</div>
          <img className='ic_more' src={icMoreGray} alt="more" id={'more_button_id_' + this.props.keyValue}
            onClick={() => this.onShowControl(this.props.keyValue, this.props.data)}
          />
        </div>
         {/* content */}
        <div className='kiosk-active__content'>         
          {status !== "online" ? 
          <div className='kiosk-item__status'>{deviceStatus}</div> 
          : 
          <div className="kiosk-item__id-number">{deviceName}</div>
          }
        </div>       
        {/* footer */}
        <div className="kiosk-item__footer">
          {status !== "online" ? (
            <a href="/">Connect ID</a>
          ) 
          :             
            null
          }
        </div>
      </StyledDeviceKiosItem>
    );
  }
}
